import {getTextPosCaret, pastePlainText, setCaretToEnd, setTextPosCaret} from "../lib/contenteditable-utils";
import {
  htmlFromMarkdownHardCase,
  htmlFromMarkdownNestedCase,
  htmlFromMarkdownNoRangesCase,
  markdownCountRanges,
  markdownFromElement,
  markdownHasNestableRanges,
  markdownIsNestable,
  markdownIsPlaintext,
  markdownWithRangesFromElement,
  normalizeHTMLForMatch,
  tagStrFromElement,
  tagStrFromMarkdown
} from "../lib/markdown-utils";


export class JWMarkdownEditor {
  derivedHTML = null;
  knowNoRanges = false;
  lastRangeCount = 0;
  knowNestableRanges = false;
  inputInnerHTML = null;
  editableElement = null;
  editing = false;
  changeCount = 0;
  onChange;
  _currentMarkdown = null;

  constructor() {
    // TODO remove?
  }

  setEditableElement(element) {
    this.editableElement = element;
    this.configureEditing();
  }

  setEditing(edit) {
    this.editing = edit;
    this.configureEditing();
  }

  configureEditing() {
    if (!this.editableElement) {
      return;
    }
    if (this.editing) {
      if (this.editableElement.contentEditable === 'false') {
        this.changeCount = 0;
        this.editableElement.contentEditable = true;
        this.editableElement.onpaste = pastePlainText;
        this.editableElement.classList.add("in-edit");
        this.editableElement.classList.remove("not-in-edit");
        this.editableElement.oninput = (event) => this.handleContentEditableInput(event);
        setCaretToEnd(this.editableElement);
      }
    } else {
      if (this.editableElement.contentEditable === 'true') {
        this.editableElement.contentEditable = false;
        this.editableElement.classList.remove("in-edit");
        this.editableElement.classList.add("not-in-edit");
      }
    }
  }

  get currentMarkdown() {
    return this._currentMarkdown;
  }

  set currentMarkdown(markdown) {
    this._currentMarkdown = markdown;
  }

  getCurrentContent() {
    if (!this.currentMarkdown) {
      if (this.knowNoRanges) {
        this.currentMarkdown = markdownFromElement(this.editableElement);
      } else {
        this.currentMarkdown = markdownWithRangesFromElement(this.editableElement);
      }
    }
    return this.currentMarkdown;
  }

  handleContentEditableInput(event) {
    this.inputInnerHTML = this.editableElement.innerHTML;
    if (this.inputInnerHTML === this.derivedHTML) {
      // event comes from rendering, no change
      this.derivedHTML = null;
      console.log("matched derived");
      return;
    }
    if (this.onChange) {
      this.onChange(this.changeCount);
    }
    // TODO factor the below?
    // ranges could have been deleted by actions in the editable element
    this.currentMarkdown = null;
    // TODO set derivedHTML to null here not in if statement below?
    const currentDerivedHTML = this.derivedHTML;
    this.fromInput();
    if (this.derivedHTML === currentDerivedHTML) {
      this.derivedHTML = null;
      return;
    }
    const pos = getTextPosCaret(this.editableElement);
    this.editableElement.innerHTML = this.derivedHTML;
    setTextPosCaret(this.editableElement, pos);
  }

  fromInput() {
    const inputMarkdownStr = markdownFromElement(this.editableElement);
    const plainText = markdownIsPlaintext(inputMarkdownStr);

    // TODO inputInnerHTML needs to be normalized somewhat because <br> and &nbsp;
    const inputTagStr = (this.knowNoRanges)
      ? normalizeHTMLForMatch(this.inputInnerHTML)
      : tagStrFromElement(this.editableElement);

    if (inputMarkdownStr === inputTagStr && plainText) {
      // everything matches and plain text
      if (this.knowNoRanges) {
        this.currentMarkdown = inputMarkdownStr;
      }
      return;
    }

    const recomputedTagStr = tagStrFromMarkdown(inputMarkdownStr, this.knowNoRanges);
    if (recomputedTagStr === inputTagStr) {
      // what's in element already matches
      return;
    }

    if (this.knowNoRanges) {
      this.currentMarkdown = inputMarkdownStr;
      this.derivedHTML = htmlFromMarkdownNoRangesCase(inputMarkdownStr);
    } else {
      const markdown = markdownWithRangesFromElement(this.editableElement);
      this.currentMarkdown = markdown;
      const rangeCount = markdownCountRanges(markdown);
      this.lastRangeCount = rangeCount;
      if (!rangeCount) {
        this.knowNoRanges = true;
        this.derivedHTML = htmlFromMarkdownNoRangesCase(markdown);
        return;
      }
      if ((plainText && (rangeCount === 1 || this.knowNestableRanges)) || markdownIsNestable(markdown)) {
        this.derivedHTML = htmlFromMarkdownNestedCase(markdown);
        return;
      }
      this.derivedHTML = htmlFromMarkdownHardCase(markdown);
    }
  }

  refreshFromContent(markdown) {
    this.fromContent(markdown);
    this.editableElement.innerHTML = this.derivedHTML;
  }

  fromContent(markdown) {
    this.currentMarkdown = markdown;
    this.knowNoRanges = false;
    this.knowNestableRanges = false;

    const plainText = markdownIsPlaintext(markdown);
    const rangeCount = markdownCountRanges(markdown);
    this.lastRangeCount = rangeCount;
    if (!rangeCount) {
      this.knowNoRanges = true;
      this.derivedHTML = htmlFromMarkdownNoRangesCase(markdown);
      return;
    }
    if (rangeCount === 1) {
      this.knowNestableRanges = true;
    } else {
      this.knowNestableRanges = markdownHasNestableRanges(markdown);
    }
    if ((plainText && this.knowNestableRanges) || markdownIsNestable(markdown)) {
      this.derivedHTML = htmlFromMarkdownNestedCase(markdown);
      return;
    }
    this.derivedHTML = htmlFromMarkdownHardCase(markdown);
  }
}
