import React from "react";
import {computed, observable, reaction} from "mobx";
import {createPopper} from '@popperjs/core';
import {scriptModel, sentenceLinePrefix, wordGroupModel, wordSelection} from "../app/app-root";
import {groupMembershipType, typeConfigurations} from "../app/entity-type-config";
import {entityIdToString, stringToEntityId} from "../lib/id-utils";
import {warning} from "../lib/warn";

export class Sentence extends React.Component {
  disposers = [];
  entity;

  constructor(props) {
    super(props);
    // this.entity = props.entity;
    // TODO setup reaction to  forceUpdate if wordGroupKey changes?
    this.disposers.push(
      reaction(
        () => this.wordGroupKey,
        () => this.forceUpdate(),
      )
    );
  }

  get entity() {
    return this.props.entity;
  }

  get mapping() {
    return scriptModel.mapping;
  }

  get wordIdRange() {
    // TODO get word id range from sentence entity
    return {start:this.entity.start_word_pos, end: this.entity.end_word_pos}
  }


  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextProps.translation !== this.props.translation || nextProps.entity !== this.props.entity;
  }

  // TODO if just going to use this in reaction probably don't need @computed as reaction already compares value?
  @computed
  get wordGroupKey() {
    let result = "";
    const range = this.mapping.idToIndexRange(this.wordIdRange);

    for (let i = range.start; i < range.end; i++) {
      const membershipType = wordGroupModel.wordGroupMembershipType[i];
      if (membershipType) {
        result += `_${scriptModel.words[i]}_${this.mapping.getId(i)}_${membershipType.group_type + !!membershipType.activated + !!membershipType.filled}`
      }
    }
    return result;
  }

  get html() {
    let html = '<span>';
    const range = this.mapping.idToIndexRange(this.wordIdRange);
    const prefix = sentenceLinePrefix(this.entity);
    html += prefix;


    for (let i = range.start; i < range.end; i++) {
      const text = scriptModel.words[i];
      // TODO calculate className for selected
      let className = 'word ';
      const wordId = this.mapping.getId(i);
      const domId = entityIdToString(wordId);
      const membershipType = wordGroupModel.wordGroupMembershipType[i];
      const last = wordGroupModel.wordGroupMembership[i] !== wordGroupModel.wordGroupMembership[i+1];
      if (membershipType && membershipType.activated) {
        className += groupMembershipType[membershipType.group_type].className;
        // className += (membershipType.resolved) ? ' resolved' : ' unresolved';
        className += (membershipType.filled) ? ' filled' : ' unfilled';
        html += `<span class="${className}" id="${domId}" `;
        html += `onclick="wordOnClickDomId('${domId}', event)" `;
        html += `onmouseenter="wordOnMouseEnterDomId('${domId}', event)" `;
        html += `onmouseleave="wordOnMouseLeaveDomId('${domId}', event)" >`;
        html += text;
        html += (last) ? '</span> ':' </span>';
      } else {
        html += `<span class="${className}" id="${domId}" `;
        html += `onclick="wordOnClickDomId('${domId}', event)" >`;
        html += text;
        html += ' </span>';
      }
    }
    html += '</span>';
    return html;
  }

  render() {
    const {translation} = this.props;

    return (
      <div className={'sentence'}>
           {/*<span className={'markdown-prefix'}*/}
           {/*       style={{verticalAlign: 'top', userSelect: 'none'}}>{prefix}</span>*/}
        <div className={'sentence-editor'}
          dangerouslySetInnerHTML={{__html: this.html}}
        />
        {translation?<div style={{whiteSpace: 'normal'}} className={'translation'}>{translation}</div>:null}
      </div>
    );
  }

}

export class WordSelectionModel {
  @observable currentWordId = null;
  @observable.ref wordRangeSelection = null;
  enabled = true;

  get mapping() {
    return scriptModel.mapping;
  }

  disable() {
     this.enabled = false;
  }

  enable() {
    this.enabled = true;
  }

  get wordRangeSelectionExclusiveEnd() {
    let selection = this.wordRangeSelection;
    if (!selection) {
      return null;
    }
    const endIndex = this.mapping.getIndex(selection.end) + 1;
    return {start: selection.start, end:this.mapping.getId(endIndex)}
  }

  getCurrentWordId() {
    return this.currentWordId;
  }

  setCurrentWordIndex(index) {
    this.setCurrentWord(this.mapping.getId(index));
  }

  getCurrentWordIndex() {
    // if (!this.currentWordId ) {
    if (this.currentWordId === null) {
      return -1;
    }
    return this.mapping.getIndex(this.currentWordId);
  }

  setCurrentWord(id) {
    if (!this.enabled) {
      warning();
      return;
    }
    this.currentWordId = id;
    this.wordRangeSelectToEnd(id);
  }

  wordRangeSelectToEnd(id) {
    //TODO move to observable reaction driven
    // TODO runInAction?
    let start = this.currentWordId;
    this.cancelWordRangeSelection();
    if (start !== null) {
      // TODO fix this up causing several reactions now
      const startIndex = this.mapping.getIndex(start);
      const endIndex = this.mapping.getIndex(id);
      if (endIndex < startIndex) {
        [start, id] = [id, start]
      }
      this.currentWordId = start;
      this.wordRangeSelection = { start, end: id };
      this.setSelectedClassName(this.wordRangeSelection);
    }
  }

  setSelectedClassName(range, selected = true) {
    this.mapping.forEachIdInRange(range, id => {
      const domId = entityIdToString(id);
      const node = document.getElementById(domId);
      if (node) {
        if (selected) {
          node.classList.add("selected");
        } else {
          node.classList.remove("selected");
        }
      }
    });
  }

  cancelWordRangeSelection() {
    if (this.wordRangeSelection) {
      this.setSelectedClassName(this.wordRangeSelection, false);
      this.wordRangeSelection = null;
      this.currentWordId = null;
    }
  }

  indexRangeForIdRange(range) {
    return this.mapping.idToIndexRange(range);
  }

  textForIndexRange(range) {
    const wordRange = scriptModel.words.slice(range.start, range.end);
    return wordRange.join(" ");
  }

  textForIdRange(range) {
    // TODO all these methods really don't belong here
    return this.textForIndexRange(this.indexRangeForIdRange(range));
  }
}

export class WordGroupPopover {
  popoverElement = null;
  popperInstance = null;
  closeTimer = null;

  constructor() {
    const element = document.getElementById('tooltip');
    this.popoverElement = element;
  }

  open(wordId) {
    // TODO get word group region for wordId
    const element = document.getElementById('tooltip');
    this.popoverElement = element;
    const popoverTextElement = element;
    const inputWordIndex = scriptModel.mapping.getIndex(wordId);
    const wordGroupRegion = wordGroupModel.wordGroupMembership[inputWordIndex];
    const wordGroup = wordGroupRegion.selected;
    if (!wordGroup.note) {
      return;
    }
    const popoverText = `${wordGroup.note}`;
    const indexRange = wordGroupRegion.wordIndexRange;
    const middleWordIndex = Math.round((indexRange.start + indexRange.end - 1) / 2);
    const targetWordId = scriptModel.mapping.getId(middleWordIndex);
    const domId = entityIdToString(targetWordId);
    const targetElement = document.getElementById(domId);

    popoverTextElement.innerText = popoverText;
    if (this.popperInstance &&
        this.popperInstance.state.elements.reference === targetElement
    ) {
      // TODO factor
      if (this.closeTimer) {
        clearTimeout(this.closeTimer);
        this.closeTimer = null;
        return;
      }
    } else if (this.popperInstance) {
      if (this.closeTimer) {
        clearTimeout(this.closeTimer);
        this.closeTimer = null;
      }
      this.popoverElement.removeAttribute('data-show');
      this.popperInstance.destroy();
      this.popperInstance = null;
    }

    this.popoverElement.setAttribute('data-show', '');
    console.log("ELEMENT: " + domId);
    this.popperInstance = createPopper(targetElement, this.popoverElement, {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ],
    });
  }

  close() {
    this.scheduleClose();
  }

  scheduleClose() {
    if (this.popperInstance && !this.closeTimer) {
      this.closeTimer = setTimeout( () => {
        this.closeTimer = null;
        // TODO can get from this.popperInstance.state.elements.popper??
        this.popoverElement.removeAttribute('data-show');
        this.popperInstance.destroy();
        this.popperInstance = null;
      }, 50);
    }
  }
}

const wordGroupPopover = new WordGroupPopover();

const wordOnClick = (id, e) => {
  e.preventDefault();
  if (e.getModifierState("Shift")) {
    wordSelection.wordRangeSelectToEnd(id);
  } else {
    wordSelection.setCurrentWord(id);
  }
};

const wordOnMouseEnter = (id, e) => {
  wordGroupPopover.open(id);
};

const wordOnMouseLeave = (id, e) => {
  wordGroupPopover.close(id);
};

const wordOnClickDomId = (idString, event) => {
  const id = stringToEntityId(idString);
  wordOnClick(id, event);
};
window.wordOnClickDomId = wordOnClickDomId;

const wordOnMouseEnterDomId = (idString, event) => {
  const id = stringToEntityId(idString);
  wordOnMouseEnter(id, event);
};
window.wordOnMouseEnterDomId = wordOnMouseEnterDomId;

const wordOnMouseLeaveDomId = (idString, event) => {
  const id = stringToEntityId(idString);
  wordOnMouseLeave(id, event);
};
window.wordOnMouseLeaveDomId = wordOnMouseLeaveDomId;




