import React from 'react';
import {observer} from "mobx-react";
import {anonymous, auth, discussionsModel, appRoot, scriptModel} from "../app/app-root";
import {warning} from "../lib/warn";
import {searchEntityByType} from "../app/entity-utils";


class MessageList extends React.Component {
  render() {
    let {messages = []} = this.props;
    if (!messages) {
      messages = [];
    }
    return (
      <ul className="message-list">
        {messages.map((message, index) => {
          return (
            <li key={message.id} className="message">
              {/*<div>{message.username}</div>*/}
              {/*<div>{message.text}</div>*/}
              <blockquote className="speech-bubble">
                <p>{message.text}</p>
                <cite>{message.username}</cite>
              </blockquote>
            </li>
          )
        })}

      </ul>
    )
  }
}


class SendMessageForm extends React.Component {
  commentAreaEl = null;

  constructor(props) {
    super(props);
    this.state = {
      message: ''
    };
    // TODO consider this pattern
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleChange(e) {
    this.setState({
      message: e.target.value
    })
  }

  handleSubmit(e) {
    this.props.sendMessage(this.state.message);
    this.setState({
      message: ''
    })
  }

  setCommentAreaEl(el) {
    this.commentAreaEl = el;
    if (el) {
      el.addEventListener('keydown', this.handleKeyPress, false);
    }
  }

  handleKeyPress(e) {
    if(e.which === 13 && !e.shiftKey && !e.repeat) {
      // e.target.closest("form").submit();
      this.handleSubmit(e);
      e.preventDefault();
    }
    // TODO how to handle this in logical way?
    //console.log("onKeyDown code: " + e.code);
    if(e.code === 'KeyR' && e.altKey && !e.repeat)  {
      this.setState({
        message: e.target.value + ' (resolved) '
      });
      e.preventDefault();
    }
  }

  render() {
    return (
      <div className={'message-form-container'}>
      <form
        className="send-message-form">
        <textarea
          style={{width:'100%'}}
          onChange={this.handleChange}
          // onKeyPress={this.handleKeyPress}
          value={this.state.message}
          disabled={anonymous()}
          ref={(el) => this.setCommentAreaEl(el)}
          placeholder="Comment"
          />
      </form>
      </div>
    )
  }
}

@observer
class DiscussionImpl extends React.Component {

  constructor(props) {
    super(props);
  }

  get focusedDiscussion() {
    return discussionsModel.focusedDiscussion;
  }

  get entityId() {
    return discussionsModel.focusedEntityId;
  }

  addComment = (comment) => {
    if (!auth.user || !this.entityId) {
      warning(1);
      return;
    }
    discussionsModel.addMessage(this.entityId, comment);
    this.sendSlackCommentMessage(comment);
  };

  async sendSlackCommentMessage(comment) {
        // TODO do somewhere more logical
    const slug = appRoot.scriptKey;
    const user = auth.user.displayName;
    const index = scriptModel.entityIdToIndex[this.entityId];
    const entity = scriptModel.orderedEntities[index];
    const isSentence = (entity.type === 'SENTENCE');
    let sentenceNum;
    if (isSentence) {
      sentenceNum = scriptModel.transcriptEntitiesIdToIndex[this.entityId] + 1;
    } else {
      const sentenceIndex = searchEntityByType(scriptModel.orderedEntities, index, ['SENTENCE'], 1);
      sentenceNum = scriptModel.transcriptEntitiesIdToIndex[scriptModel.orderedEntities[sentenceIndex].id] + 1;
    }
    let subject;
    if (isSentence) {
      subject =  `S-${sentenceNum}: ${entity.content}`;
    } else {
      subject =  `Near S-${sentenceNum}: (${entity.type}) ${entity.content}`;
    }
    const box = 'https://masala-v1-server.jiveworld.com';
    const result = await fetch(`${box}/slack_message?slug=${slug}&user=${user}&subject=${subject}&comment=${comment}`)
  }

  render() {
    return (
      <div>
        <MessageList messages={this.focusedDiscussion}/>
        <SendMessageForm sendMessage={this.addComment}/>
      </div>
    );
  }
}

export const Discussion = DiscussionImpl;
