import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader
} from "mdbreact";
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import {getScriptEditorInstance, wordGroupActions} from "../app/app-root";
import {WordGroupTasksSheet} from "./word-group-tasks-sheet";

class WordGroupTasksSheetDialogState {
  @observable open = false;
}

const dialogState = new WordGroupTasksSheetDialogState();

export function openWordGroupTasksSheetDialog() {
  // TODO
  dialogState.open = true;
  const scriptEditor = getScriptEditorInstance();
  scriptEditor.deactivateKB();
}

export const WordGroupTasksSheetDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
    const scriptEditor = getScriptEditorInstance();
    if (dialogState.open) {
      scriptEditor.deactivateKB();
    } else {
      scriptEditor.activateKB();
    }
  };

  return (
    <MDBModal size="xl" isOpen={dialogState.open} toggle={toggleModal}>
      {/*<MDBModalHeader*/}
      {/*  className="text-center"*/}
      {/*  titleClass="w-100 font-weight-bold"*/}
      {/*  toggle={toggleModal}*/}
      {/*>*/}
      {/*  {"Word Group Sheet"}*/}
      {/*</MDBModalHeader>*/}
      <MDBModalBody>
        <WordGroupTasksSheet/>
      </MDBModalBody>
    </MDBModal>
  );
});


