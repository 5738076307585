import {observer} from "mobx-react";
import React from "react";
import {alerts} from "../app/app-root";

export const Alerts = observer(() => {
  const {alert} = alerts;
  return (
    <>
      {(alert) ? <div className={'alert' + alert.level}>{alert.message}</div> : null}
    </>
  );
});
