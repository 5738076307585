import React from "react";
import {typeConfigurations} from "../app/entity-type-config";
import {JWMarkdownEditor} from "./markdown-editor";
import {reaction} from "mobx";
import {textToHtml} from "./script-editor";


export class ScriptLineEditor extends React.Component {
  disposers = [];
  entity;
  editor;
  getIsEditingFunc;

  constructor(props) {
    super(props);
    // TODO make conditional on type supporting editable content
    this.entity = props.entity;
    this.translation = props.translation;
    let EditorClass = typeConfigurations[this.entity.type]['editorClass'];
    EditorClass = EditorClass || JWMarkdownEditor;
    this.editor = new EditorClass();
    this.getIsEditingFunc = props.getIsEditing;
    this.disposers.push(
      reaction(
        () => this.isEditing,
        () => this.configureEditor(),
      )
    );
    this.configureEditor();
  }

  get isEditing() {
    return this.getIsEditingFunc(this.entity.id);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextProps.translation !== this.props.translation;
  }

  getContentFromEditor() {
    return this.editor.getCurrentContent();
  }

  pushBuffer(entity, content) {
    return this.props.pushBuffer(entity.id, content);
  }

  popBuffer(entity) {
    return this.props.popBuffer(entity.id);
  }

  componentWillUnmount() {
    for (const disposer of this.disposers) {
      disposer();
    }
    this.disposers = [];
  }

  pushBufferIfNeeded() {
    if (this.isEditing) {
      // TODO check if changes otherwise don't save?
      this.pushBuffer(this.entity, this.getContentFromEditor());
    }
  }

  set editableElement(element) {
    this.editor.setEditableElement(element);
    this.configureEditor();
  }

  configureEditor() {
    let content = this.isEditing ? this.popBuffer(this.entity) : null;
    if (!content) {
      content = this.entity.content;
    }
    this.editor.fromContent(content);
    this.editor.setEditing(this.isEditing);
    // TODO invert set unset in setActiveEntityEditor?
    this.props.setActive(this, !this.isEditing);
  }

  revert() {
    const content = this.entity.content;
    this.editor.refreshFromContent(content);
  }

  render() {
    const typeConfig = typeConfigurations[this.entity.type];
    let html = this.editor.derivedHTML;
    const {translation} = this.props;


    // TODO rework the texteditor handling
    if (!html ) {
      const text = this.editor.currentContent;
      html = text ? textToHtml(text) : '';
    }
    let prefix = typeConfig.prefix;
    if (typeof prefix === 'function') {
      prefix = prefix(this.entity)
    }
    return (
      <div className={typeConfig.containerClassName} style={{whiteSpace: 'nowrap'}}>
        {(prefix)
          ? <span className={'markdown-prefix'}
                  style={{verticalAlign: 'top', userSelect: 'none'}}>{prefix}</span>
          : null
        }
        <div
          className={'markdown-editor not-in-edit'}
          ref={(element) => this.editableElement = element}
          contentEditable={false}
          dangerouslySetInnerHTML={{__html: html}}
        />
        {translation?<div style={{whiteSpace: 'normal'}} className={'translation'}>{translation}</div>:null}
      </div>
    );
  }
}
