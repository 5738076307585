import {observable} from "mobx";
import {warning} from "../lib/warn";


export class AlertsModel {
  @observable.ref alert;

  doAlert({bell = false, message = '', level = 'info'}) {
    if (bell) {
      warning();
    }
    this.alert = {message, level};
  }
}
