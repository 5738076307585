import {Auth} from "./auth";
import {AlertsModel} from "./alerts-model";
import {ScriptEditorModel} from "./script-editor-model";
import {DiscussionsModel} from "./discussions-model";
import {reaction, runInAction} from "mobx";
import {ScriptLoader} from "./script-loader";
import {DbPaths} from "./db";
import {WordSelectionModel} from "../components/sentence";
import {PositionalMapping} from "../lib/positional-ids";
import {WordGroupActions, WordGroupModel} from "../lib/word-group-model";
import {TranslationActions} from "./translation-actions";
import version from "../lib/macros/version.macro";
import {WordGroupTasksModel} from "./word-group-tasks-model";
import {AuditLogActions} from "./audit-log";

window.version = version;

export class AppRoot {
  scriptKey = "";
  loader = null;
  dbPaths = null;

  constructor() {
    this.loader = new ScriptLoader();
    reaction(
      () => this.loader.notifyDataUpdate,
      () => this.testLoaded()
    );
  }

  // TODO move all this loading stuff to an AppRoot because it's not just state for the "editor" but the load
  //  of everything needs to be coordinated
  load(key) {
    if (navigator.userAgent.indexOf('Chrome') === -1) {
      window.alert("Script Editor only works in Google Chrome");
      return;
    }
    this.loader.load(key);
    // TODO switch this after load the project not before, keep current LOADED key on "this"
    this.scriptKey = key;
    this.dbPaths = new DbPaths(key);
  }

  // TODO move to correct place
  testLoaded() {
    runInAction(() => {
      scriptModel.words = this.loader.rootedEntitiesDoc.words;
      scriptModel.positionalMapping = this.loader.rootedEntitiesDoc.positionalMapping;
      scriptModel.mapping = new PositionalMapping(scriptModel.positionalMapping);
      scriptModel.rootedEntitiesDict = this.loader.rootedEntitiesDoc.entities;
      scriptModel.rootedOrder = this.loader.rootedEntitiesDoc.order;
      scriptModel.anchoredEntitiesDict = this.loader.anchoredEntitiesDoc;
      scriptModel.entityTranslations = this.loader.entityTranslationsDoc;
      scriptModel.editorState = this.loader.editorStateDoc;
      wordGroupModel.wordGroupRegionsDict = this.loader.wordGroupRegionsDoc.regions || {};
      scriptModel.dbPaths = this.dbPaths;
      discussionsModel.setMessagesDict(this.loader.discussionsDoc);
    });
  }
}

let scriptEditor = null;
export const scriptModel = new ScriptEditorModel();
export const alerts = new AlertsModel();
export const auth = new Auth();
export const discussionsModel = new DiscussionsModel();
export const auditLogActions = new AuditLogActions();
export const wordSelection = new WordSelectionModel();
export const wordGroupModel = new WordGroupModel();
export const wordGroupActions = new WordGroupActions();
export const translationActions = new TranslationActions();
export const wordGroupTasksModel = new WordGroupTasksModel();
export const appRoot = new AppRoot();

export function sentenceLinePrefix(entity) {
  const sentenceIndex = scriptModel.transcriptEntitiesIdToIndex[entity.id];
  return '[' + (sentenceIndex + 1) + '] ';
}

export function setScriptEditorInstance(editor) {
  scriptEditor = editor;
}

export function getScriptEditorInstance() {
  return scriptEditor;
}

export function anonymous() {
  return !auth.user;
}


export function findForward(arr, index, f) {
  const len = arr.length;
  for (let i = index; i < len; i++) {
    if (f(arr[i])) {
      return i;
    }
  }
  return -1;
}

export function findReverse(arr, index, f) {
  for (let i = index; i >= 0; i--) {
    if (f(arr[i])) {
      return i;
    }
  }
  return -1;
}




