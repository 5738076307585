import {appRoot} from "./app-root";
import {openTranslationDialog} from "../components/translation-dialog";

export class TranslationActions {

  get dbPaths() {
    return appRoot.dbPaths;
  }

  translationDialogAction(entity, translation) {
    openTranslationDialog(entity, translation);
  }

  async updateTranslation(id, translation) {
    const entityTranslationsDocRef = this.dbPaths.entityTranslationsDocRef;
    const update = {[id]: translation};
    const result = await entityTranslationsDocRef.set(update, {merge:true});
  }
}


