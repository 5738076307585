import React from 'react';
import {observer} from "mobx-react";
import {scriptModel, auth, appRoot} from "../app/app-root";
import {signInWithGoogle, firebaseAuth} from "../app/firebase.init";


const StatusBarElement = ({children}) => {
  return (
   <div
     style = {{
       paddingTop: 10,
       paddingLeft: 30,
       paddingRight: 40,
       display: 'inline-block',
     }}
   >
     {children}
   </div>
  );
};

export const StatusBar = observer( () => {
  return (
    <div className={'status-bar'}>
      {/*<StatusBarElement>{appRoot.scriptKey}/{scriptModel.currentChapterTitle}</StatusBarElement>*/}
      <div className='status-bar-element status-bar-chapter'>{appRoot.scriptKey}/{scriptModel.currentChapterTitle}</div>
      <div style={{display:'inline-block', float:'right'}}>
        {(auth.user) ?
          <>
            <img style={{width:35, height:35,  objectFit:'cover', borderRadius:'50%',  padding:3}} src={auth.user.photoURL}/>
            {/*<span>{auth.user.email}</span>*/}
            <span style={{textDecoration:'underline', marginLeft:6, marginRight:6}} onClick={() => firebaseAuth.signOut()}>(Sign-out)</span>
          </>
          : <span style={{textDecoration:'underline', marginLeft:6, marginRight:6, fontWeight:900, fontSize: "1.5em", color:"red", background:"yellow"}} onClick={signInWithGoogle}>(Sign-in)</span>
        }
        <a
          href={'https://jiveworld.slite.com/api/s/note/Cp7aFgzKqKAvCx5cLUMqeu/Script-Editor-Cheat-Sheet'}
          target="_blank"
        >
          H&nbsp;
        </a>
      </div>
    </div>
  );
});

