import {computed, observable, reaction} from "mobx";
import {appRoot, auth} from './app-root';
import {randomString} from "./entity-utils";


export class DiscussionsModel {
  @observable focusedEntityId = null;
  @observable.ref messagesDict = {};

  constructor() {
    reaction(
      () => this.focusedDiscussion,
      (discussion) => console.log(discussion),
    );
  }

  setFocused(id) {
    this.focusedEntityId = id;
  }

  setMessagesDict(messagesDict) {
    this.messagesDict = messagesDict;
  }

  @computed({ keepAlive: true })
  get messages() {
    return Object.values(this.messagesDict);
  }

  @computed({ keepAlive: true })
  get entityIdToActiveDiscussion() {
    const result = {};
    for ( const [id, discussion] of Object.entries(this.entityIdToDiscussion)) {
      if (!discussion[discussion.length - 1].text.includes('(resolved)')) {
        result[id] = discussion;
      }
    }
    return result;
  }

  @computed({ keepAlive: true })
  get entityIdToDiscussion() {
    const result = {};
    for (const message of this.messages) {
      let messages = result[message.entityId];
      if (!messages) {
        messages = [];
        result[message.entityId] = messages;
      }
      messages.push(message);
    }
    for (const discussion of Object.values(result)) {
      discussion.sort((a, b) => a.time - b.time);
    }
    return result;
  }

  get focusedDiscussion() {
    if (!this.focusedEntityId) {
      return null;
    }
    return this.entityIdToDiscussion[this.focusedEntityId];
  }

  addMessage(entityId, text) {
    const user = auth.user;
    const username = user.displayName;
    this.addUpdateMessage({entityId, username, text});
  }

  async addUpdateMessage({id, entityId, username, text}) {
    const dbPaths = appRoot.dbPaths;
    const discussionsDocRef = dbPaths.discussionsDocRef;
    const time = Date.now();
    const messageData = { entityId, username, text, time};
    const messageId = id ? id: `MESSAGE:${randomString(12)}`;
    messageData['id'] = messageId;
    const message= {};
    message[messageId] = messageData;
    const result = await discussionsDocRef.update(message);
  }
}
