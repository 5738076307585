
// TODO ISSUES <br> elements magically appearing at end of contenteditable spans
// TODO &nbsp; actually needed at end of text before adding word need to translate to space in markdown version
// TODO need to always generate at least some number of &nbsp at end of edittable span
// TODO divs outlines are rectangular and look better than spans
// TODO divs work well to click on even with no content inside
// TODO Sentences are always div contenteditable and other things are div until certain number chars then turn span?

/*
 * Simplified tag style representation with no Ranges encoded
 */
export function tagStrFromElement(element) {
  // TODO
  console.log("tagStrFromElement not implemented");
}

/*
 * Simplified tag style representation with no Ranges encoded
 */
export function tagStrFromInnerHTML(htmlStr) {
  return normalizeHTMLForMatch(htmlStr.replace(/<\/*span[^>]*>/g,''));
}

/*
 * Regular markdown string with no Ranges encoded
 */
export function markdownFromElement(element) {
  let text = element.innerText;
  text = text.replace(/&nbsp;/g," ");
  text = text.replace(/\u00a0/g," ");
  return text;

}

/*
 * Simplified tag style representation with no Ranges encoded
 */
export function tagStrFromMarkdown(str, knowNoRanges = false) {
  str = htmlFromMarkdownNoRangesCase(str);
  if (knowNoRanges) {
    return str;
  }
  return str.replace(/<range:[^>]*?>/g,'');
}

/*
 * Markdown with Ranges encoded
 */
export function markdownWithRangesFromElement(element) {

}

/*
 * HTML from markdown with Ranges encoded
 */
export function htmlFromMarkdown(str) {
  // TODO non optimized but not used
  return htmlFromMarkdownHardCase(str);
}

export function htmlFromMarkdownNoRangesCase(str) {
  return str.replace(/_([^_]*?)_/g,'_<i><b>$1</b></i>_');
}

export function htmlFromMarkdownNestedCase(str) {
  str = htmlFromMarkdownNoRangesCase(str);
  // TODO CSS className
  str = str.replace(/<range:b:([^>]*?)>/g,'<span data-rangeid="$1">');
  return str.replace(/<range:e:[^>]*?>/g,'</span>');
}

export function htmlFromMarkdownHardCase(str) {

}

export function normalizeHTMLForMatch(htmlStr) {
  // TODO
  return htmlStr.replace(/&nbsp;/g,' ');
}

export function markdownCountRanges(str) {
  const matches = str.match(/<range:b/g);
  if (matches) {
    return matches.length;
  }
  return 0;
  // str.indexOf('_') !== -1
}

export function markdownHasItalics(str) {
  const matches = str.match(/_/g);
  if (matches) {
    return matches.length;
  }
  return 0;
}

export function markdownIsPlaintext(str, checkRanges = false) {
  return !(markdownHasItalics(str) || (checkRanges && markdownCountRanges(str)));
}

export function markdownIsNestable(str) {

}

// TODO OR NESTED OR NESTABLE NOT DISJOINT?
export function markdownHasNestableRanges(str) {
  const intervals = getRangeIntervalsFromMarkdown(str);
  return areIntervalsNestable(intervals);
}

export function rangeString(rangeId, begin=true) {
  if (begin) {
    return `<range:b:${rangeId}>`;
  } else {
    return `<range:e:${rangeId}>`;
  }
}

export function splitMarkdownWithRanges(str) {
  // returns array of strings and range strings, join gives back markdown
}

export function insertRangeInMarkdown(str, rangeId, start, end) {

}

export function intervalPairOverlaps(a, b) {
  return !(b.start > a.end || a.start > b.end);
}

export function intervalPairNestable(a, b) {
  // TODO boundaries in practice?
  if (b.start > a.end || a.start > b.end) {
    return true;
  }
  [a, b] = (a.start < b.start) ? [a, b] : [b, a];
  return b.end <= a.end;
}

export function forEveryPair(arr, f) {
  const len = arr.length;
  const maxFirst = len - 1;
  for (let i = 0; i < maxFirst; i++) {
    for (let j = i + 1; i < len; j++) {
      if (!f(arr[i],arr[j])) {
        return false;
      }
    }
  }
  return true;
}

export function forEachPair(arr, f) {
  const len = arr.length;
  const maxFirst = len - 1;
  for (let i = 0; i < maxFirst; i++) {
    for (let j = i + 1; i < len; j++) {
      f(arr[i],arr[j]);
    }
  }
}

export function areIntervalsNestable(arr) {
  return forEveryPair(arr, (a, b) =>  intervalPairNestable(a, b));
}

export function getRangeIntervalsFromMarkdown(str) {

}

export function getMarkdownIntervalsFromMarkdown(str) {

}

