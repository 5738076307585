
import {randomString, searchEntityByType} from "./entity-utils";
import {auth, appRoot, scriptModel, wordSelection} from "./app-root";
import moment from 'moment';


export class AuditLogActions {

  async addLogMessage(logMessage, entity, oldEntity=null) {
    entity = {...entity};
    oldEntity = {...oldEntity};
    if (entity.time) {
      delete entity.time;
    }
    if (entity.before) {
      delete entity.before;
    }
    if (entity.after) {
      delete entity.after;
    }
    if (entity.end_word_pos) {
      delete entity.end_word_pos;
    }
    if (entity.start_word_pos) {
      delete entity.start_word_pos;
    }
    if (entity.transcriptWordCount) {
      delete entity.transcriptWordCount;
    }

    // factor function with above
    if (oldEntity) {
      delete oldEntity.id;
      delete oldEntity.type;
      if (oldEntity.time) {
        delete oldEntity.time;
      }
      if (oldEntity.before) {
        delete oldEntity.before;
      }
      if (oldEntity.after) {
        delete oldEntity.after;
      }
      if (oldEntity.end_word_pos) {
        delete oldEntity.end_word_pos;
      }
      if (oldEntity.start_word_pos) {
        delete oldEntity.start_word_pos;
      }
      if (oldEntity.transcriptWordCount) {
        delete oldEntity.transcriptWordCount;
      }
    }
    const index = scriptModel.entityIdToIndex[entity.id];
    let sentencePointer = null;
    if (index) { // TODO will be little bug not worry about now
      const entity = scriptModel.orderedEntities[index];
      const isSentence = (entity.type === 'SENTENCE');
      let sentenceNum;
      if (isSentence) {
        sentenceNum = scriptModel.transcriptEntitiesIdToIndex[entity.id] + 1;
      } else {
        const sentenceIndex = searchEntityByType(scriptModel.orderedEntities, index, ['SENTENCE'], 1);
        sentenceNum = scriptModel.transcriptEntitiesIdToIndex[scriptModel.orderedEntities[sentenceIndex].id] + 1;
      }
      if (isSentence) {
        sentencePointer =  `S-${sentenceNum}`;
      } else {
        sentencePointer =  `Near S-${sentenceNum}`;
      }
    }

    const auditLogDocRef = appRoot.dbPaths.rootRef.collection("Script__auditLog").doc(appRoot.scriptKey);
    const user = auth.user.email;
    const time = Date.now();
    const _time = moment().format('MMMM Do YYYY, h:mm:ss a');
    const messageData = {user, message: logMessage, time, _time, entity};
    if (oldEntity) {
      messageData.oldEntity = oldEntity;
      if (oldEntity.group_type) {
        const text = wordSelection.textForIdRange({start: oldEntity.startPosition, end: oldEntity.endPosition});
        oldEntity.text = text;
      }
    }
    if (sentencePointer) {
      messageData.sentencePointer = sentencePointer;
    }
    if (scriptModel.entityTranslations && scriptModel.entityTranslations[entity.id]) {
      messageData.translation = scriptModel.entityTranslations[entity.id];
    }
    if (entity.type === 'WORD_GROUP') {
      const text = wordSelection.textForIdRange({start:entity.startPosition, end:entity.endPosition});
      entity.text = text;
    }
    const messageId = `LOG:${randomString(12)}`;
    const messages = {};
    messages[messageId] = messageData;
    const result = await auditLogDocRef.set({messages:messages}, {merge: true});
  }
}

