const WALL_BIT = 1 << 15;
const MASK = 0xffff & ~WALL_BIT;

// Javascript only has builtin numeric sort on typed arrays, leverage that
// indexFunction must yield val 0 to 65535 and array length maximum is 32767
export function fastSortOnAtomIndex(array, indexFunction) {
  const count = array.length;
  if (count >= 32767) {
    // throw
  }
  /* eslint-disable */
  const sortingArray = new Uint32Array(count);
  /* eslint-enable */
  for (let i = 0; i < count; i++) {
    sortingArray[i] = (indexFunction(array[i]) << 16) | WALL_BIT | i;
  }
  sortingArray.sort(); // native numeric sort
  const copiedArray = array.slice();
  for (let i = 0; i < count; i++) {
    array[i] = copiedArray[sortingArray[i] & MASK];
  }
}

export function _fastSortOnAtomIndex(array, indexFunction) {
  array.sort((a, b) => indexFunction(a) - indexFunction(b));
}

export function testFastSortOnAtomIndex() {
  let a = [345, 11, 45, 23, 35, 4];
  a = a.map(v => {
    return { value: v };
  });

  function valueFunc(a) {
    return a.value;
  }
  fastSortOnAtomIndex(a, valueFunc);
  console.log(a);
}



