import {observable, reaction} from "mobx";
import {firebaseAuth} from "./firebase.init";

// TODO move this stuff to some sort of jiveworld user db in firestore?
export const emailUsername = {
  'patrick@jiveworld.com':'patrick',
  'andrew@jiveworld.com':'andrew',
  'joseph@jiveworld.com':'joseph',
  'santi@jiveworld.com':'santi',
  'daniel@jiveworld.com':'daniel',
  'jason@jiveworld.com':'jason',
};

export class Auth {
  @observable.ref user;

  constructor() {
    firebaseAuth.onAuthStateChanged(user => {
      this.user = user;
    });
    reaction(
      () => this.user,
      (user) => console.log(user)
    )
  }

  // TODO move someplace else, make user class?
  jiveworldUsername() {
    if (!this.user) {
      return null;
    }
    return emailUsername[this.user.email];
  }
}

