import React from "react"
import {observer} from "mobx-react";
import {scriptModel, wordGroupActions, wordGroupModel, wordGroupTasksModel} from "../app/app-root";
import {WordGroupThread} from "./word-group-thread";
import {computed, observable, reaction} from "mobx";
import {ResilientRowCursor} from "../lib/resilient-row-cursor";
import {ScrollingRows} from "../lib/word-group-edit/grid";
import {stripTrailingPunctuation} from "../app/entity-utils";
import {warning} from "../lib/warn";


@observer
class WordGroupTasksSheetImpl extends React.Component {
  disposers = [];
  cursor;
  keyDivEl;
  @observable lastKeyPress = 1;
  wordGroupThreadEl;


  constructor(props) {
    super(props);
    this.cursor = new ResilientRowCursor(row => row.id);
    this.cursor.setRows(wordGroupTasksModel.filtered);
    this.disposers.push(reaction(
      () => wordGroupTasksModel.filtered,
      (filtered) => this.cursor.setRows(filtered)
    ));
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentWillUnmount() {
    for (const disposer of this.disposers) {
      disposer();
    }
  }

  getKeyDiv() {
    return this.keyDivEl;
  }

  getCursor() {
    return this.cursor.getCursor();
  }

  @computed({ keepAlive: true})
  get currentRegionId() {
    if (this.getCursor()) {
      return this.getCursor().key;
    }
    return null;
  }

  @computed({ keepAlive: true })
  get currentRegion() {
    if (!this.currentRegionId) {
      return null;
    }
    return wordGroupModel.regionIdToRegion[this.currentRegionId];
  }

  get currentSentence() {
    const sentenceIndex = this.currentSentenceIndex;
    if (sentenceIndex !== -1) {
      return scriptModel.transcriptRootedEntities[sentenceIndex];
    }
  }

  get mapping() {
    return scriptModel.mapping;
  }

  sentenceIndex(region) {
    const startIndex = region.wordIndexRange.start;
    if (startIndex === null) {
      return null;
    }
    return scriptModel.transcriptEntitiesWordIntervals.containing(startIndex);
  }

  get currentSentenceIndex() {
    if (!this.currentRegion) {
      return -1;
    }
    return this.sentenceIndex(this.currentRegion)
  }


  getSuggestionTranscriptText(suggestion) {
    const startIndex = this.mapping.getIndex(suggestion.startPosition);
    const endIndex = this.mapping.getIndex(suggestion.endPosition);
    const text = scriptModel.words.slice(startIndex, endIndex).join(' ');
    return stripTrailingPunctuation(text);
  }

  setKeyDiv(element) {
    this.keyDivEl = element;
    if (element) {
      element.tabindex = -1;
      element.addEventListener('keydown', this.handleKeyPress);
      if (!document.activeElement) {
        this.setFocus();
      }
    }
  }

  setFocus() {
    if (this.keyDivEl)  {
      this.keyDivEl.focus();
      setTimeout( () => {
          this.keyDivEl.focus();
        }, 1000
      )
    }
  }

   resolveActivatedAction() {
    if (!this.currentRegionId) {
      warning();
      return;
    }
    wordGroupActions.resolve(this.currentRegionId, 'ACTIVATED');
  }

  resolveDeactivatedAction() {
    if (!this.currentRegionId) {
      warning();
      return;
    }
    wordGroupActions.resolve(this.currentRegionId, 'DEACTIVATED');
  }

  toggleVocabFilter() {
    wordGroupTasksModel.filterForVocab = !wordGroupTasksModel.filterForVocab;
    console.log("toggling vocab filter");
  }

  toggleInboxFilter() {
    wordGroupTasksModel.filterForInbox= !wordGroupTasksModel.filterForInbox;
  }

  // TODO use keyboardist instead?
  handleKeyPress(e) {
    const focused = document.activeElement;
    if (!focused || focused !== this.keyDivEl) {
      return;
    }
    this.lastKeyPress = e.code;
    if (e.code === 'KeyS') {
      e.preventDefault();
      this.wordGroupThreadEl.focusSuggestionInput();
    }
    if (e.code === 'KeyC') {
      e.preventDefault();
      this.wordGroupThreadEl.focusCommentInput();
    }
    if (e.code === 'KeyV') {
      e.preventDefault();
      this.toggleVocabFilter()
    }
    if (e.code === 'KeyA') {
      e.preventDefault();
      this.resolveActivatedAction()
    }
    if (e.code === 'KeyI') {
      e.preventDefault();
      this.toggleInboxFilter();
    }
    if (e.code === 'KeyD' && e.ctrlKey) {
      e.preventDefault();
      this.resolveDeactivatedAction()
    }
  }

  handleThreadAreaSubmit() {
    this.setFocus();
  }

  Filters = observer((props) => {
    return(
      <>
        <div className="wg-filter-column">
          <div>
            <label >
              <input
                type="checkbox"
                checked={wordGroupTasksModel.filterForUnresolved}
                onChange={(e) => wordGroupTasksModel.filterForUnresolved = e.target.checked}
              />
              <span> unresolved </span>
            </label>
            {/*<label >*/}
            {/*  <input*/}
            {/*    type="checkbox"*/}
            {/*    checked={wordGroupTasksModel.filterForResolved}*/}
            {/*    onChange={(e) => wordGroupTasksModel.filterForResolved = e.target.checked}*/}
            {/*  />*/}
            {/*  <span> closed </span>*/}
            {/*</label>*/}
            {/*<label >*/}
            {/*  <input*/}
            {/*    type="checkbox"*/}
            {/*    checked={wordGroupTasksModel.showDeactivated}*/}
            {/*    onChange={(e) => wordGroupTasksModel.showDeactivated = e.target.checked}*/}
            {/*  />*/}
            {/*  <span> show deleted </span>*/}
            {/*</label>*/}
          </div>
        </div>
        <div className="wg-filter-column">
          <div>
            <label >
              <input
                type="checkbox"
                checked={wordGroupTasksModel.filterForUnfilled}
                onChange={(e) => wordGroupTasksModel.filterForUnfilled = e.target.checked}
              />
              <span> unfilled </span>
            </label>
            <label >
              <input
                type="checkbox"
                checked={wordGroupTasksModel.filterForFilled}
                onChange={(e) => wordGroupTasksModel.filterForFilled = e.target.checked}
              />
              <span> filled </span>
            </label>
          </div>
        </div>
        <div className="wg-filter-column">
          <div>
            <label >
              <input
                type="checkbox"
                checked={wordGroupTasksModel.filterForVocab}
                onChange={(e) => wordGroupTasksModel.filterForVocab = e.target.checked}
              />
              <span> vocab </span>
            </label>
            <label >
              <input
                type="checkbox"
                checked={wordGroupTasksModel.filterForTricky}
                onChange={(e) => wordGroupTasksModel.filterForTricky = e.target.checked}
              />
              <span> tricky </span>
            </label>
          </div>
        </div>
        <div className="wg-filter-column">
          <div style={{margin:'.5em'}}>
            <input
              type="text"
              value={wordGroupTasksModel.filterText}
              onChange={(e) => wordGroupTasksModel.filterText = e.target.value}
            />
          </div>
             <label >
              <input
                type="checkbox"
                checked={wordGroupTasksModel.filterForInbox}
                onChange={(e) => wordGroupTasksModel.filterForInbox= e.target.checked}
              />
              <span> inbox </span>
            </label>
        </div>
      </>
    );
  });

  typeSymbols = {
    vocab:'',
    tricky:'~',
    sic:'$',
  };

  TaskRow = (region) => {
    const item = region.selected;
    let note = item.note || '';
    const delimiter = note.includes('=') ? '|':'=';
    note = item.note;
    //if (!note && item.group_type !== 'tricky') {
    if (!note) {
      note = '???';
    }
    const typeIndicator = this.typeSymbols[item.group_type];
    return (
      <div>
        {'S-' + (this.sentenceIndex(region) + 1) + ':'} &nbsp;
        <span style={{}}>{typeIndicator}</span>
        {/*<span style={{}}>&lt;{typeIndicator}</span>*/}
        <span>{this.getSuggestionTranscriptText(item)} </span> {(note)?< span> {delimiter + ' ' + note}</span>:''}
        {/*<span style={{}}>&gt;</span>*/}
      </div>
    );
  };

  // Translation = ({sentenceId}) => {
  //   const translation = scriptModel.entityTranslations[sentenceId];
  //   return (
  //     <div className='wg-sheet-translation'>
  //       {(translation ? translation: null)}
  //     </div>
  //   );
  // };

  render() {
    const Filters = this.Filters;
    // const Translation = this.Translation;
    return (
      <div className='wg-sheet'>
        <div
          className='wg-sheet-right-sidepanel'
        >
          {(this.currentRegionId) ?
            <WordGroupThread
              regionId={this.currentRegionId}
              ref={(element) => this.wordGroupThreadEl = element}
              onSubmit={() => this.handleThreadAreaSubmit()}
              adminUI
            /> :
            ''
          }
        </div>
        <div
          className='wg-sheet-left-sidepanel'
          tabIndex={-1}
          id='wg-sheet-sheet-left-sidepanel'
          ref={(element) => this.setKeyDiv(element)}
          // onKeyPress={(e) => this.handleKeyPress(e)}
        >
          <div className='wg-sheet-filters'>
            <Filters/>
            {/*{this.lastKeyPress}*/}
          </div>
          <div className='wg-sheet-left-bottom'>
            {/*{(scriptModel.hasTranslations && this.currentSentence) ?*/}
            {/*  <Translation sentenceId={this.currentSentence.id}/> :*/}
            {/*  null*/}
            {/*}*/}
            <div className='wg-sheet-tasks'>
              <ScrollingRows
                getCursor={this.cursor.getCursor}
                setCursor={this.cursor.setCursor}
                rowComponent={this.TaskRow}
                allRows={wordGroupTasksModel.filtered}
                getRowKey={row => row.id}
                rowHeight={30}
                keyEventElement={() => this.getKeyDiv()}
              />
            </div>
          </div>
         </div>
      </div>
    );
  }
}
export const WordGroupTasksSheet = WordGroupTasksSheetImpl;


