import {observable, reaction} from "mobx";
import {DbPaths} from "./db";


export class ScriptLoader {
  key = null;
  @observable notifyDataUpdate = 1;
  @observable.ref rootedEntitiesDoc = null;
  @observable.ref anchoredEntitiesDoc = null;
  @observable.ref entityTranslationsDoc = null;
  @observable.ref discussionsDoc = null;
  @observable.ref wordGroupRegionsDoc = null;
  @observable.ref editorStateDoc = null;

  fireStoreDisposers = [];
  reactionDisposers = [];

  constructor() {
    this.watch(() => this.rootedEntitiesDoc);
    this.watch(() => this.anchoredEntitiesDoc);
    this.watch(() => this.entityTranslationsDoc);
    this.watch(() => this.discussionsDoc);
    this.watch(() => this.wordGroupRegionsDoc);
    this.watch(() => this.editorStateDoc);
  }

  watch(f) {
    this.reactionDisposers.push(reaction(f, () => this.handleUpdate()));
  }

  async load(key) {
    if (true || key !== this.key) {
      this.close();
      this.dbPaths = new DbPaths(key);
      this.rootedEntitiesDoc = null;
      this.anchoredEntitiesDoc = null;
      this.entityTranslationsDoc = null;
      this.discussionsDoc = null;
      this.editorStateDoc = null;
      this.key = key;

      // TODO actually save and run the listener disposers
      this.dbPaths.rootedEntitiesDocRef.onSnapshot(doc => {
        console.log("*** rooted entities doc update");
        this.rootedEntitiesDoc = doc.data();
      });

      this.dbPaths.anchoredEntitiesDocRef.onSnapshot(doc => {
        console.log("*** anchored entities doc update");
        this.anchoredEntitiesDoc = doc.data();
      });

      this.dbPaths.entityTranslationsDocRef.onSnapshot(doc => {
        console.log("*** translations doc update");
        this.entityTranslationsDoc = doc.data();
      });

      this.dbPaths.groupSuggestionsDocRef.onSnapshot(doc => {
        console.log("*** word group doc update");
        this.wordGroupRegionsDoc = doc.data();
      });

      this.dbPaths.discussionsDocRef.onSnapshot(doc => {
        console.log("*** discussions doc update");
        this.discussionsDoc = doc.data();
      });

      this.dbPaths.editorStateDocRef.onSnapshot(doc => {
        console.log("*** editor state doc update");
        const data = doc.data();
        this.editorStateDoc = data ? data: {};
      });
    }
  }

  close() {
    for (const disposer of this.fireStoreDisposers) {
      disposer();
    }
    //TODO call the reaction disposers and init again on open?
  }

  handleUpdate() {
    // TODO consider if required that discussion doc is present before notifying?
    if (
      this.rootedEntitiesDoc &&
      this.anchoredEntitiesDoc &&
      this.entityTranslationsDoc &&
      this.discussionsDoc &&
      this.wordGroupRegionsDoc &&
      this.editorStateDoc
    ) {
      console.log("INSIDE Loader handleUpdate ");
      this.notifyDataUpdate++;
    }
  }

  onContentUpdated(cb) {
    this.reactionDisposers.push(
      reaction(
        () => this.notifyDataUpdate,
        () => cb(this)
      )
    );
  }
}
