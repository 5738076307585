import React from "react";
import Root from "./containers/root";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";

//const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const BASE_PATH = "script-editor";

// class ScrollToTop extends React.Component {
//   componentDidUpdate(prevProps) {
//     if (this.props.location !== prevProps.location) {
//       window.scrollTo(0, 0);
//     }
//   }
//
//   render() {
//     return this.props.children;
//   }
// }
//
// ScrollToTop = withRouter(ScrollToTop);

export default () => (
  <Router basename={BASE_PATH}>
    {/*<ScrollToTop>*/}
      <Route path="/:scriptKey" component={Root} />
    {/*</ScrollToTop>*/}
  </Router>
);
//<Route path="/:episodeSlug/:chapterNumber?" component={Root} />
