import {computed, observable} from "mobx";
import {auth, wordGroupModel} from "./app-root";


export class WordGroupTasksModel {
  @observable filterForResolved = false;
  @observable filterForUnresolved = false;
  @observable filterForFilled = false;
  @observable filterForUnfilled = false;
  @observable filterForVocab = false;
  @observable filterForTricky = false;
  @observable filterForUsername = null;
  @observable filterText = null;
  @observable filterForInbox = false;
  @observable showDeactivated = false;

  @computed({ keepAlive: true })
  get filterRegExps() {
    const result = [];
    if (this.filterText && this.filterText.trim().length) {
      result.push(new RegExp(this.filterText, 'i'));
    }
    if (this.filterForInbox) {
      result.push(new RegExp('@'+ auth.jiveworldUsername(), 'i'));
    }
    return result.length ? result: null;
  }

  @computed({ keepAlive: true })
  get filtered() {
    let types = [];
    if (this.filterForVocab) {
      types.push('vocab');
    }
    if (this.filterForTricky) {
      types.push('tricky');
    }
    if (!types.length) {
      types = null;
    }

    const result = wordGroupModel.wordGroupRegions.filter((region, index) => {
      if (this.filterForUnresolved && !(region.hasComment && !region.resolved)) {
        return false;
      }
      if (this.filterForResolved && !region.resolved) {
        return false;
      }
      if (this.filterForFilled && !region.filled) {
        return false;
      }
      if (this.filterForUnfilled && region.filled) {
        // TODO in wordgroupregion
        return false;
      }
      if (types) {
        if (!types.includes(region.group_type)) {
          return false;
        }
      }
      if (!this.showDeactivated) {
        if (!region.activated) {
          return false;
        }
      }
      const res = this.filterRegExps;
      if (res) {
        const content = this.searchableTextPerRegion[index];
        for (const re of res) {
          if (content.search(re) === -1) {
            return false;
          }
        }
      }
      return true;
    });
    // console.log(result);
    return result;
  }

  @computed({ keepAlive: true })
  get searchableTextPerRegion() {
    const result = [];
    return wordGroupModel.wordGroupRegions.map( (region) => {
      let text = '';
      const item = region.thread[0];
      text += item.author;
      if (item.type === 'COMMENT') {
        text += item.text;
      }
      return text;
    });
  }
}
