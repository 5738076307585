import {db} from "./firebase.init";


export class DbPaths {
  key;
  _anchoredEntitiesDocRef = null;
  _rootedEntitiesDocRef = null;
  _entityTranslationsDocRef = null;
  _discussionsDocRef = null;
  _groupSuggestionsDocRef = null;
  _editorStateDocRef = null;

  constructor(key) {
    this.key = key;
    this.db = db;
  }

  get rootRef() {
    return this.db;
  }

  get anchoredEntitiesDocRef() {
    if (!this._anchoredEntitiesDocRef) {
      this._anchoredEntitiesDocRef = this.rootRef.collection("Script__anchoredEntities").doc(this.key);
    }
    return this._anchoredEntitiesDocRef;
  }

  get rootedEntitiesDocRef() {
    if (!this._rootedEntitiesDocRef) {
      this._rootedEntitiesDocRef = this.rootRef.collection("Script__verbatim").doc(this.key);
    }
    return this._rootedEntitiesDocRef;
  }

  get entityTranslationsDocRef() {
    if (!this._entityTranslationsDocRef) {
      this._entityTranslationsDocRef = this.rootRef.collection("Entity__translations").doc(this.key);
    }
    return this._entityTranslationsDocRef;
  }

  get groupSuggestionsDocRef() {
    if (!this._groupSuggestionsDocRef) {
      this._groupSuggestionsDocRef = this.rootRef.collection("Script__groupSuggestions").doc(this.key);
    }
    return this._groupSuggestionsDocRef;
  }

  get discussionsDocRef() {
    if (!this._discussionsDocRef) {
      this._discussionsDocRef = this.rootRef.collection("Entity__discussions").doc(this.key);
    }
    return this._discussionsDocRef;
  }

  get editorStateDocRef() {
    if (!this._editorStateDocRef) {
      this._editorStateDocRef = this.rootRef.collection("Script__editor").doc(this.key);
    }
    return this._editorStateDocRef;
  }
}
