import DiffMatchPatch, {
  DIFF_DELETE,
  DIFF_EQUAL,
  DIFF_INSERT
} from "diff-match-patch";
import {
  copyPositionalMapping,
  forEachPositionalId,
  recordInsertContent,
  recordRemoveContent,
  recordSwapsertContent,
  SHIFTED_BY_DELETE_FLAG
} from "./positional-ids";

function patchFromDiff(
  offset,
  oldContent,
  oldPositionalMapping,
  newContent,
  diffList,
  swapsertSet = null
) {
  let position = 0;
  const newPositionalMapping = copyPositionalMapping(oldPositionalMapping);
  let insertCount = 0;
  let removeCount = 0;

  for (const diff of diffList) {
    const diffOp = diff[0];
    const len = diff[1].length;

    if (diffOp === DIFF_EQUAL) {
      position += len;
    }

    if (diffOp === DIFF_INSERT) {
      for (let i = 0; i < len; i++) {
        const pos = position + i;
        // if (swapsertSet && swapsertSet.has(pos)) {
        //   recordSwapsertContent(newPositionalMapping, pos);
        // } else {
          recordInsertContent(newPositionalMapping, pos + offset);
        // }
      }
      position += len;
      insertCount += len;
    }

    if (diffOp === DIFF_DELETE) {
      for (let i = 0; i < len; i++) {
        recordRemoveContent(newPositionalMapping, position + offset);
      }
      removeCount += len;
    }
  }

  return { newPositionalMapping, insertCount, removeCount };
}

function doDiffAndPatch(
  offset,
  oldContent,
  oldPositionalMapping,
  newContent,
  swapsertSet = null
) {
  const oldLineDelimited = oldContent.join("\n");
  const newLineDelimited = newContent.join("\n");

  const dmp = new DiffMatchPatch();
  const asCharCodes = dmp.diff_linesToChars_(
    oldLineDelimited,
    newLineDelimited
  );
  const diffs = dmp.diff_main(asCharCodes.chars1, asCharCodes.chars2, false);
  return patchFromDiff(
    offset,
    oldContent,
    oldPositionalMapping,
    newContent,
    diffs,
    swapsertSet
  );
}

function getDeleteCount(positionalMapping) {
  let deleteCount = 0;
  forEachPositionalId(positionalMapping, id => {
    if (positionalMapping.positions[id] & SHIFTED_BY_DELETE_FLAG) {
      deleteCount++;
    }
  });
  return deleteCount;
}

function scanMaxPosition(positionalMapping) {
  let maxPosition = 0;
  const positions = positionalMapping.positions;
  forEachPositionalId(positionalMapping, id => {
    if (!(positions[id] & SHIFTED_BY_DELETE_FLAG)) {
      const position = positions[id];
      maxPosition = position > maxPosition ? position : maxPosition;
    }
  });
  return maxPosition;
}

/******************************************
 *
 *  ES6 exports
 *
 */

export { getDeleteCount, scanMaxPosition, patchFromDiff, doDiffAndPatch };
