import React from "react";
import {computed, observable, reaction} from "mobx";
import {appRoot, auth, anonymous, scriptModel, wordGroupActions, wordGroupModel, wordSelection} from "../app/app-root";
import {observer} from "mobx-react";
import {MDBBtn, MDBInput} from "mdbreact";
import moment from 'moment';
import {stripTrailingPunctuation, trimPunctuation} from "../app/entity-utils";

@observer
class WordGroupThreadImpl extends React.Component {
  disposers = [];
  // @observable wordGroupRegionId;
  @observable focusedSuggestionId;
  // TODO use like word group dialog? or still use word group dialog for now?
  @observable suggestionTemplate;

  // editable values
  @observable.ref selectedWordRange = {};
  @observable noteTextValue = '';
  @observable commentTextValue = '';
  suggestionInputEl;
  commentInputEl;


  constructor(props) {
    // TODO make work with not yet existing region
    super(props);
    // this.wordGroupRegionId = props.regionId;
    this.configureEditableValues();
    this.disposers.push(
      reaction(
        () => this.focusedSuggestion,
        () => this.configureEditableValues()
      )
    );
    this.handleCommentAreaKeypress = this.handleCommentAreaKeypress.bind(this);
  }

  get wordGroupRegionId() {
    return this.props.regionId;
  }

  componentWillUnmount() {
    for (const disposer of this.disposers) {
      disposer();
    }
  }

  configureEditableValues() {
    const suggestion = this.focusedSuggestion;
    if (suggestion.note) {
      this.noteTextValue = suggestion.note.trimStart();
    } else {
      this.noteTextValue = '';
    }
    this.selectedWordRange = this.sentenceWordGroupRange;
  }

  get mapping() {
    return scriptModel.mapping;
  }

  get adminUI() {
    return this.props.adminUI;
  }

  @computed({keepAlive: true })
  get wordGroupRegion() {
    return wordGroupModel.regionIdToRegion[this.wordGroupRegionId];
  }

  @computed({keepAlive: true })
  get thread() {
    if (this.adminUI) {
      return this.wordGroupRegion.filteredThread;
    } else {
      return this.wordGroupRegion.filteredThread.filter( (item) => {
        return item.type === 'COMMENT';
      })
    }
  }

  get selectedSuggestion() {
    return this.wordGroupRegion.selected;
  }

  // TODO or compute focusedSuggestionId , either _focusedSuggestionId or selectedSuggestion.id
  get focusedSuggestion() {
    if (this.focusedSuggestionId) {
      return this.wordGroupRegion.suggestions.find(s => s.id === this.focusedSuggestionId);
    }
    return this.selectedSuggestion;
  }

  getSuggestionForId(id) {
    return this.wordGroupRegion.suggestions.find(s => s.id === id);
  }

  get regionStartIndex() {
    return this.wordGroupRegion.wordIndexRange.start;
  }

  @computed({keepAlive: true })
  get sentenceIndex() {
    const startIndex = this.regionStartIndex;
    if (startIndex === null) {
      return null;
    }
    return scriptModel.transcriptEntitiesWordIntervals.containing(startIndex);
  }

  get sentenceWordIndexRange() {
    return scriptModel.transcriptEntitiesWordIntervals.at(this.sentenceIndex);
  }

  get sentenceEntity() {
    return scriptModel.transcriptRootedEntities[this.sentenceIndex];
  }

  @computed({keepAlive: true })
  get sentenceStartIndex() {
    return this.sentenceWordIndexRange.start;
  }

  @computed({keepAlive: true })
  get sentenceWords() {
    const range = this.sentenceWordIndexRange;
    return scriptModel.words.slice(range.start, range.end);
  }

  @computed({keepAlive: true })
  get sentenceWordGroupRange() {
    const focused = this.focusedSuggestion;
    let range = {start:focused.startPosition, end:focused.endPosition};
    range = this.mapping.idToIndexRange(range);
    const sentenceStart = this.sentenceStartIndex;
    return {start:range.start - sentenceStart, end:range.end - sentenceStart};
  }

  @computed({keepAlive: true })
  get selectedWordIdRange() {
    const sentenceStart = this.sentenceStartIndex;
    const startIndex = sentenceStart + this.selectedWordRange.start;
    const endIndex = sentenceStart + this.selectedWordRange.end;
    return {start: this.mapping.getId(startIndex), end:this.mapping.getId(endIndex)};
  }

  @computed({keepAlive: true })
  get suggestionEdited() {
    // TODO compare edited values with focused suggestion values
    const range = this.selectedWordIdRange;
    const note = this.noteTextValue;
    let edited = false;

    const suggestion = this.focusedSuggestion;
    if (suggestion.note) {
      if (note !== suggestion.note.trimStart())
        edited = true;
    } else {
      if (note.length > 0) {
        edited = true;
      }
    }
    edited = edited || (range.start !== suggestion.startPosition || range.end !== suggestion.endPosition);
    if (edited) {
      wordSelection.disable();
    } else {
      wordSelection.enable();
    }
    return edited;
  }

  getSuggestionTranscriptText(suggestion) {
    const startIndex = this.mapping.getIndex(suggestion.startPosition);
    const endIndex = this.mapping.getIndex(suggestion.endPosition);
    const text = scriptModel.words.slice(startIndex, endIndex).join(' ');
    return stripTrailingPunctuation(text);
  }

  handleNoteTextChange(e) {
    this.noteTextValue = e.target.value;
  }

  handleCommentTextChange(e) {
    this.commentTextValue = e.target.value;
  }

  handleThreadItemClick(item, event) {
    if (anonymous()) {
      return;
    }
    if (item.type === 'WORD_GROUP') {
      if (event.shiftKey) {
        const select = item.id !== this.selectedSuggestion.id;
        this.changeSelection(item, select);
      }
      this.focusedSuggestionId = item.id;
    }
  }

  changeSelection(item, selected) {
    if (!item.type === 'WORD_GROUP') {
      // TODO throw?
      return;
    }
    if (selected) {
      wordGroupActions.select(this.wordGroupRegionId, item);
    } else {
      wordGroupActions.resetSelection(this.wordGroupRegionId);
    }
  }

  focusSuggestionInput() {
    if (this.suggestionInputEl) {
      this.suggestionInputEl.setFocus();
      setTimeout(() => {
          this.suggestionInputEl.setFocus();
        }, 400
      );
    }
  }

  focusCommentInput() {
    if (this.commentInputEl) {
      this.commentInputEl.focus();
      setTimeout(() => {
          this.commentInputEl.focus();
        }, 400
      );
    }
  }

  handleWordRangeChange(range) {
    this.selectedWordRange = range;
  }

  handleCommentSubmit() {
    wordGroupActions.addComment(this.wordGroupRegionId, this.commentTextValue);
    this.sendSlackCommentMessage();
    this.commentTextValue = '';
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  }

  async sendSlackCommentMessage() {
        // TODO do somewhere more logical
    const slug = appRoot.scriptKey;
    const user = auth.user.displayName;
    const item = this.selectedSuggestion;
    let note = item.note || '';
    const delimiter = note.includes('=') ? '|':'=';
    note = item.note;
    //if (!note && item.group_type !== 'tricky') {
    if (!note) {
      note = '???';
    }
    const typeIndicator = this.typeSymbols[item.group_type];
    const transcriptText = this.getSuggestionTranscriptText(item);
    const sentenceNum = this.sentenceIndex + 1;
    const subject =  `S-${sentenceNum}: <${typeIndicator} ${transcriptText} ${(note)? delimiter + ' ' + note:''}>`;
    const box = 'https://masala-v1-server.jiveworld.com';
    const result = await fetch(`${box}/slack_message?slug=${slug}&user=${user}&subject=${subject}&comment=${this.commentTextValue}`)
  }


  setCommentAreaEl(el) {
    this.commentInputEl = el;
    if (el) {
      el.addEventListener('keydown', this.handleCommentAreaKeypress, false);
    }
  }

  handleCommentAreaKeypress(e) {
    e.stopPropagation();
    if(e.which === 13 && !e.shiftKey && !e.repeat) {
      e.preventDefault();
      this.handleCommentSubmit();
    }
    // TODO how to handle this in logical way?
    if(e.code === 'KeyR' && e.altKey && !e.repeat)  {
      this.commentTextValue = this.commentTextValue + ' (resolved) ';
      e.preventDefault();
    }
  }

  handleSuggestionInputKeypress(e) {
    e.stopPropagation();
    if(e.which === 13) {
      e.preventDefault();
      this.handleAddSuggestion();
    }
    if(e.which === 27) {
      e.preventDefault();
      this.revert();
    }
  }

  revert() {
    this.configureEditableValues();
  }

  handleAddSuggestion() {
    if (!scriptModel.editingEnabled) {
      return;
    }
    const range = this.selectedWordIdRange;
    const note = this.noteTextValue;
    const group_type = this.wordGroupRegion.group_type;
    wordGroupActions.addSuggestion(this.wordGroupRegionId, {
      group_type,
      note,
      startPosition: range.start,
      endPosition: range.end,
    });
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  }

  resolve(state) {
    wordGroupActions.resolve(this.wordGroupRegionId, state);
  }

  ThreadItemPostInfo = ({item}) => {
    return (
      <div className={'thread-item-post-info'}>
        <span>{item.author}</span>&nbsp;<span style={{float:'right'}}>{moment.unix(item.time).format('MMMM Do YYYY, h:mm:ss a')}</span>
      </div>
    );
  };

  ThreadItemContainer = ({item, focused, selected, onClick, children}) => {
    // TODO
    let className = 'thread-item ' + ((focused) ? 'thread-item-focused ': '');
    className += (selected) ? 'thread-item-selected ' : '';
    const ThreadItemPostInfo = this.ThreadItemPostInfo;
    return (
      <div
        className={className}
        onClick={onClick}
      >
        {children}
        <ThreadItemPostInfo item={item}/>
      </div>
    );
  };

  CommentItemBody = ({item}) => {
    // TODO
    return (
      <div>
        {item.text}
      </div>
    );
  };

  // SuggestionItemCore = (props) => {
  //   // TODO
  //   return (
  //     null
  //   );
  // };

  typeSymbols = {
    vocab:'',
    tricky:'~',
    sic:'$',
  };

  SuggestionItemBody = ({item}) => {
    // TODO ability select/deselect
    let note = item.note || '';
    const delimiter = note.includes('=') ? '|':'=';
    note = item.note;
    //if (!note && item.group_type !== 'tricky') {
    if (!note) {
      note = '???';
    }
    const typeIndicator = this.typeSymbols[item.group_type];
    return (
      <>
        <span style={{}}>&lt;{typeIndicator}</span>
        <span>{this.getSuggestionTranscriptText(item)} </span> {(note)?< span> {delimiter + ' ' + note}</span>:''}
        <span style={{}}>&gt;</span>
      </>
    )
    ;
  };

  ActionItemBody = ({item}) => {
    let description = '';
    switch(item.action) {
      case 'RESOLVE':
        description = (item.state === 'ACTIVATED') ? 'Closed Activated' : 'Closed Deactivated';
        break;
      case 'RESET_SELECTION':
        description = 'Reset Selection';
        break;
      default:
        break;
    }
    const target = (item.action === 'SELECT') ? this.getSuggestionForId(item.target) : null;
    const SuggestionItemBody = this.SuggestionItemBody;
    const ThreadItemPostInfo = this.ThreadItemPostInfo;
    return (
      <>
        {(target) ?
          <>
            <div>Selected</div>
            <div style={{paddingLeft:20}}>
              <SuggestionItemBody
                item={target}
              />
              <ThreadItemPostInfo item={target}/>
            </div>
          </>
          :
          <div>{description}</div>
        }
      </>
    );
  };

  translation = (sentenceId) => {
    const translation = scriptModel.entityTranslations[sentenceId];
    return (
      <div className='wg-thread-translation'>
        {(translation ? translation: null)}
      </div>
    );
  };

  itemTypeComponent = {
    WORD_GROUP: this.SuggestionItemBody,
    ACTION: this.ActionItemBody,
    COMMENT: this.CommentItemBody,
  };

  typeNoteName = {
    vocab: 'Definition',
    tricky: 'Note',
    sic: 'Note',
  };

  render() {
    if (!this.wordGroupRegion) {
      return (
        <></>
      );
    }
    // const Translation = this.Translation;
    const translation  = (scriptModel.hasTranslations && this.sentenceEntity) ? () => this.translation(this.sentenceEntity.id):null;
    const noteName = this.typeNoteName[this.wordGroupRegion.group_type];
    return (
      <div className={'work-group-thread'}>
        <div className={'add-suggestion-form'}>
          <WordRangeSelect
            words={this.sentenceWords}
            translation={translation}
            getRange={() => this.selectedWordRange}
            onRangeChange={(range) => this.handleWordRangeChange(range)}
          />
          {/*{(scriptModel.hasTranslations && this.sentenceEntity) ?*/}
          {/*  <Translation sentenceId={this.sentenceEntity.id}/> :*/}
          {/*  null*/}
          {/*}*/}
          <div>
            { noteName ?
              <div style={{display: 'flex'}}>
                <div style={{width: "max-content"}}></div>
                <div
                  style={{flexGrow: 1}}
                >
                  <MDBInput label={noteName}
                            disabled={anonymous()}
                            type="text"
                            style={{width: '100%'}}
                            className="word-group-note-edit"
                            value={this.noteTextValue}
                            onChange={(e) => this.handleNoteTextChange(e)}
                            onKeyDown={(e) => this.handleSuggestionInputKeypress(e)}
                            ref={element => this.suggestionInputEl = element}
                  />
                </div>
              </div>
              : ''
            }
            <MDBBtn
              color="info"
              size='sm'
              disabled={anonymous() || !this.suggestionEdited || !scriptModel.editingEnabled}
              onClick={() => this.handleAddSuggestion()}
            >
              Update
            </MDBBtn>
            {/*{ !this.adminUI*/}
            {/*  ?*/}
              <MDBBtn
                color="info"
                size='sm'
                disabled={anonymous()}
                onClick={() => this.resolve('DEACTIVATED')}
              >
                Delete
              </MDBBtn>
            {/*  : null*/}
            {/*}*/}
          </div>
        </div>
        <div className={'word-group-thread-container'}>
          {this.thread.map(item => {
            const Container = this.ThreadItemContainer;
            const Body = this.itemTypeComponent[item.type];
            const focused = this.focusedSuggestionId === item.id;
            const selected = this.selectedSuggestion.id === item.id;
            return (
              <Container
                focused={focused}
                selected={selected}
                item={item}
                onClick={(e) => this.handleThreadItemClick(item, e)}
              >
                <Body
                  item={item}
                />
              </Container>
            );
          })}
        </div>



        <div className={'word-group-comment-area'}>
         <textarea
          style={{width:'100%'}}
          onChange={(e) => this.handleCommentTextChange(e)}
          // onKeyPress={(e) => this.handleCommentAreaKeypress(e)}
          ref={(el) => this.setCommentAreaEl(el)}
          value={this.commentTextValue}
          disabled={anonymous()}
          placeholder="Comment"
          ref={element => this.setCommentAreaEl(element)}
          />
        </div>
          {/*{ this.adminUI && !(this.wordGroupRegion.activated && this.wordGroupRegion.resolved)*/}
          {/*  ?*/}
          {/*  <MDBBtn*/}
          {/*    color="info"*/}
          {/*    size='sm'*/}
          {/*    disabled={anonymous()}*/}
          {/*    onClick={() => this.resolve('ACTIVATED')}*/}
          {/*  >*/}
          {/*    Close Activated*/}
          {/*  </MDBBtn>*/}
          {/*  :*/}
          {/*  null*/}
          {/*}*/}
          {/*{(this.adminUI && (!this.wordGroupRegion.resolved || this.wordGroupRegion.activated))*/}
          {/*  ?*/}
          {/*  <MDBBtn*/}
          {/*    color="info"*/}
          {/*    size='sm'*/}
          {/*    disabled={anonymous()}*/}
          {/*    onClick={() => this.resolve('DEACTIVATED')}*/}
          {/*  >*/}
          {/*    Delete*/}
          {/*  </MDBBtn>*/}
          {/*  :*/}
          {/*  null*/}
          {/*}*/}
        </div>
    );
  }
}
export const WordGroupThread = observer(WordGroupThreadImpl);


function inRange(range, val) {
  return (range.start <= val && range.end > val);
}

function extendRange(range, val) {
  if (inRange(range, val)) {
    return range;
  }
  if (val < range.start) {
    return {start: val, end: range.end};
  }
  return {start: range.start, end: val + 1};
}


const WordRangeSelect = observer(({words, translation, getRange, onRangeChange}) => {
  const range = getRange();

  const onClick = (index, e) => {
    if (anonymous()) {
      return;
    }
    e.preventDefault();
    if (e.getModifierState("Shift")) {
      onRangeChange(extendRange(range, index))
    } else {
      onRangeChange({start:index, end: index + 1})
    }
  };

  //const text = stripTrailingPunctuation(words.slice(range.start, range.end).join(' '));
  const text = trimPunctuation(words.slice(range.start, range.end).join(' '));

  return (
    <div>
      <div className={'word-range-select'}>
        {words.map((word, index) => {
          const className = inRange(range, index) ? 'selected' : '';
          return (
            <span
              className={className}
              onClick={(e) => onClick(index, e)}
            >{word} </span>
          )
        })}
      </div>
      { (translation) ?
        translation()
        :
        ''
      }
      <MDBInput label='Transcript' disabled value={text}/>
    </div>
  );
});



