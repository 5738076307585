

export function getTextPosCaret(element) {
  return getCaretCharacterOffsetWithin(element);
}

export function setTextPosCaret(element, pos) {
  setPos(element, pos);
}

export function setCaretToEnd(target)
{
  const range = document.createRange();
  range.selectNodeContents(target);
  range.collapse(false);
  const sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
  // target.focus();
  // range.detach(); // optimization?
}

function getCaretCharacterOffsetWithin(element) {
  let caretOffset = 0;
  const doc = element.ownerDocument || element.document;
  const win = doc.defaultView || doc.parentWindow;
  let sel;
  if (typeof win.getSelection != "undefined") {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      const range = win.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
  } else if ((sel = doc.selection) && sel.type != "Control") {
    const textRange = sel.createRange();
    const preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint("EndToEnd", textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  console.log("caret:"  + caretOffset);
  return caretOffset;
}

function setCaret(el, pos) {
  let curNode=0;
  const range = document.createRange();
  const sel = window.getSelection();
  let lastPos = pos;

  if (el.childNodes.length > 0) {
    while (lastPos > el.childNodes[curNode].childNodes[0].textContent.length) {
      lastPos = lastPos - el.childNodes[curNode].childNodes[0].textContent.length;
      curNode++;
    }
    range.setStart(el.childNodes[curNode].childNodes[0], lastPos);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  }
  el.focus();
}

function setPos(target, position) {
  if (position >= 0) {
    const selection = window.getSelection();
    const range = createRange(target, {
      count: position
    });
    if (range) {
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}

function createRange(node, chars, range) {
  if (!range) {
    range = document.createRange();
    range.selectNode(node);
    range.setStart(node, 0);
  }
  if (chars.count === 0) {
    range.setEnd(node, chars.count);
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length;
      } else {
        range.setEnd(node, chars.count);
        chars.count = 0;
      }
    } else {
      for (let lp = 0; lp < node.childNodes.length; lp++) {
        range = createRange(node.childNodes[lp], chars, range);
        if (chars.count === 0) {
          break;
        }
      }
    }
  }
  return range;
}

export function pastePlainText(event) {
  event.preventDefault();
  document.execCommand('inserttext', false, event.clipboardData.getData('text/plain'));
}
