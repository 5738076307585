import {getTextPosCaret, pastePlainText, setCaretToEnd, setTextPosCaret} from "../lib/contenteditable-utils";


export class TextEditor {
  derivedText = null;
  inputInnerText = null;
  editableElement = null;
  editing = false;
  changeCount = 0;
  onChange;
  _currentContent = null;

  constructor() {
    // TODO remove?
  }

  setEditableElement(element) {
    this.editableElement = element;
    this.configureEditing();
  }

  setEditing(edit) {
    this.editing = edit;
    this.configureEditing();
  }

  configureEditing() {
    if (!this.editableElement) {
      return;
    }
    if (this.editing) {
      if (this.editableElement.contentEditable === 'false') {
        this.changeCount = 0;
        this.editableElement.contentEditable = true;
        this.editableElement.onpaste = pastePlainText;
        this.editableElement.classList.add("in-edit");
        this.editableElement.classList.remove("not-in-edit");
        this.editableElement.oninput = (event) => this.handleContentEditableInput(event);
        this.editableElement.onkeypress = (event) => this.handleKeypress(event);
        setCaretToEnd(this.editableElement);
      }
    } else {
      if (this.editableElement.contentEditable === 'true') {
        this.editableElement.contentEditable = false;
        this.editableElement.classList.remove("in-edit");
        this.editableElement.classList.add("not-in-edit");
      }
    }
  }

  get currentContent() {
    return this._currentContent;
  }

  set currentContent(content) {
    this._currentContent = content;
  }

  getCurrentContent() {
    if (!this.currentContent) {
      this.currentContent = this.editableElement.innerText;
    }
    return this.currentContent;
  }

  handleKeypress(event) {
    if (event.code === 'Enter') {
      event.preventDefault();
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const br = document.createElement('br');

      range.deleteContents();
      range.insertNode(br);
      range.setStartAfter(br);
      range.setEndAfter(br);
      range.collapse(false);

      selection.removeAllRanges();
      selection.addRange(range);
      this.handleContentEditableInput(event);
    }
  }

  handleContentEditableInput(event) {
    this.inputInnerText = this.editableElement.innerText;
    if (this.onChange) {
      this.onChange(this.changeCount);
    }
    this.fromInput();
  }

  fromInput() {
    this.currentContent = this.inputInnerText;
  }

  refreshFromContent(content) {
    this.fromContent(content);
    this.editableElement.innerText = this.currentContent;
  }

  fromContent(content) {
    this.currentContent = content;
  }
}
