import { searchsorted } from "./searchsorted";
import { SortedPoints } from "./sorted-points";

export function midPointOf(interval) {
  return (interval.start + interval.end) >>> 1;
}

// instances are immutable
export class SortedIntervals {
  domainStart = 0;
  domainEnd = 0;
  startPoints = [];
  endPoints = [];
  _midPoints = null;

  constructor(data = null) {
    if (data) {
      this.startPoints = data.startPoints;
      this.endPoints = data.endPoints;
    }
  }

  static fromIntervals(intervals) {
    const startPoints = [];
    const endPoints = [];
    for (const interval of intervals) {
      if (interval) {
        startPoints.push(interval.start);
        endPoints.push(interval.end - 1);
      } else {
        // TODO something more reasonable or don't allow this null case
        startPoints.push(startPoints[startPoints.length - 1] + 1);
        endPoints.push(startPoints[startPoints.length - 1]);
      }
    }
    return new SortedIntervals({ startPoints, endPoints });
  }

  get midPoints() {
    if (!this._midPoints) {
      const len = this.startPoints.length;
      this._midPoints = new Array(len).fill(0);
      for (let i = 0; i < len; i++) {
        this._midPoints[i] = (this.startPoints[i] + this.endPoints[i]) >>> 1;
      }
    }
    return this._midPoints;
  }

  get length() {
    return this.startPoints.length;
  }

  interval(idx) {
    // EXCLUSIVE? TODO
    return { start: this.startPoints[idx], end: this.endPoints[idx] };
  }

  at(idx) {
    return this.interval(idx);
  }

  validReturnIndex(idx) {
    if (this.checkValidIndex(idx)) {
      return idx;
    }
    return -1;
  }

  checkValidIndex(idx) {
    return idx >= 0 && idx < this.startPoints.length;
  }

  validReturnInterval(startIdx, endIdx) {
    if (startIdx < 0 || endIdx < 0) {
      return null; // TODO or return empty interval?
    }

    // EXCLUSIVE
    if (startIdx >= endIdx) {
      return null; // TODO possible?
    }

    return { start: startIdx, end: endIdx };
  }

  getStartPoints() {
    return new SortedPoints(this.startPoints);
  }

  getEndPoints() {
    return new SortedPoints(this.endPoints);
  }

  getMidPoints() {
    return new SortedPoints(this.midPoints);
  }

  containing(value) {
    const idx = this.lastStartsBeforeOrAt(value);
    if (idx >= 0 && value < this.endPoints[idx]) {
      return idx;
    }
    return -1;
  }

  doesContain(index, value) {
    if (index < 0) {
      return false;
    }
    // TODO non inclusive end EXCLUSIVE
    return this.startPoints[index] <= value && this.endPoints[index] > value;
  }

  doesStartBeforeOrAt(index, value) {
    return this.startPoints[index] <= value;
  }

  lastStartsBeforeOrAt(value) {
    let idx = searchsorted(this.startPoints, value, true);
    // idx will be the index of the first interval with start value after input value
    // change to preceding interval which must have start value less than or equal input value
    idx--;
    return this.validReturnIndex(idx);
  }

  // first interval that starts after or at input value
  firstStartsAfterOrAt(value) {
    let idx = searchsorted(this.startPoints, value);
    return this.validReturnIndex(idx);
  }

  // first interval that starts after input value
  firstStartsAfter(value) {
    // TODO note this implementation will not return intervals which start at the input value
    let idx = searchsorted(this.startPoints, value, true);
    return this.validReturnIndex(idx);
  }

  // the last interval that ends before or at value
  lastEndsBeforeOrAt(value) {
    let idx = searchsorted(this.endPoints, value, true);
    // idx will be the index of the first interval with end value after input value
    // change to preceding interval which must have end value less than or equal input value
    idx--;
    return this.validReturnIndex(idx);
  }

  firstEndsAfter(value) {
    const idx = searchsorted(this.endPoints, value, true);
    // idx will be the index of the first interval with end value after input value
    return this.validReturnIndex(idx);
  }

  shiftToExclusiveEnd(endIdx) {
    // EXCLUSIVE
    return endIdx >= 0 ? endIdx + 1 : endIdx;
  }

  rangeContained(startValue, endValue) {
    // TODO FIX ME
    // TODO trims earlier zero length intervals?
    let startIdx = this.firstStartsAfterOrAt(startValue);
    let endIdx = this.lastEndsBeforeOrAt(endValue);
    endIdx = this.shiftToExclusiveEnd(endIdx);

    return this.validReturnInterval(startIdx, endIdx);
  }

  hasContained(startValue, endValue) {
    // TODO need to optimize of not?
    return !!this.rangeContained(startValue, endValue);
  }

  hasIntersecting(startValue, endValue) {
    // TODO need to optimize of not?
    return !!this.rangeIntersecting(startValue, endValue);
  }

  rangeIntersecting(startValue, endValue) {
    let startIdx = this.firstEndsAfter(startValue);
    let endIdx = this.lastStartsBeforeOrAt(endValue);
    endIdx = this.shiftToExclusiveEnd(endIdx);

    return this.validReturnInterval(startIdx, endIdx);
  }

  retrieveRange(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(this.interval(i));
    }
    return result;
  }

  valueBounds(startIndex, endIndex) {
    return {
      start: this.startPoints[startIndex],
      end: this.endPoints[endIndex]
    };
  }

  inplaceShiftValuesAboveValue(shiftAtValue, shiftAmount) {
    // TODO
  }

  translateStartPointsToValues(startPointIndexes) {
    return startPointIndexes.map(index => this.startPoints[index]);
  }

  translateEndPointsToValues(endPointIndexes) {
    // TODO a hack? think end point handling through
    return endPointIndexes.map(index => this.endPoints[index - 1]);
  }

  translate(startPoints, endPoints) {
    return new SortedIntervals({
      startPoints: this.translateStartPointsToValues(startPoints),
      endPoints: this.translateEndPointsToValues(endPoints)
    });
  }

  forEach(f) {
    for (let i = 0; i < this.startPoints.length; i++) {
      f(this.interval(i), i);
    }
  }

  map(f) {
    const result = [];
    for (let i = 0; i < this.startPoints.length; i++) {
      result.push(f(this.interval(i), i));
    }
    return result;
  }

  sortedIntervalsMapRangeContains(intervals) {
    const results = [];
    intervals.forEach(interval => {
      const rangeContained = this.rangeContained(interval.start, interval.end);
      if (!rangeContained) {
        console.log("no range contained");
      }
      if (rangeContained) {
        // EXCLUSIVE
        results.push({
          start: rangeContained.start,
          end: rangeContained.end + 1
        });
      } else {
        // TODO deal with this other way?
        results.push(null);
      }
    });
    return results;
  }

  getGapIntervals(minsize = -1) {
    const resultStartPoints = [];
    const resultEndPoints = [];

    if (this.startPoints.length) {
      if (this.startPoints[0] - this.domainStart > minsize) {
        resultStartPoints.push(this.domainStart);
        resultEndPoints.push(this.startPoints[0]);
      }

      const gapStarts = this.endPoints;
      const gapEnds = this.startPoints;
      const count = gapStarts.length - 1;

      for (let i = 0; i < count; i++) {
        if (gapEnds[i + 1] - gapStarts[i] > minsize) {
          resultStartPoints.push(gapStarts[i]);
          resultEndPoints.push(gapEnds[i + 1]);
        }
      }
    }
    // TODO add last interval to domainEnd
    return new SortedIntervals({
      startPoints: resultStartPoints,
      endPoints: resultEndPoints
    });
  }

  getExpandShortIntervals(expandsize, direction = "LEFT") {
    const count = this.startPoints.length;
    const resultStartPoints = this.startPoints.slice();
    const resultEndPoints = this.endPoints.slice();
    for (let i = 0; i < count; i++) {
      const start = resultStartPoints[i];
      const end = resultEndPoints[i];
      const needsExpand = end - start < expandsize;
      if (needsExpand) {
        if (direction === "LEFT") {
          resultStartPoints[i] = end - expandsize;
        } else {
          // TODO
        }
      }
    }
    return new SortedIntervals({
      startPoints: resultStartPoints,
      endPoints: resultEndPoints
    });
  }

  get openTransitions() {
    return this.startPoints;
  }
}
