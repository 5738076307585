export const SHIFTED_BY_DELETE_FLAG = 1 << 25;
const MASK_OUT_DELETE_FLAG = ~SHIFTED_BY_DELETE_FLAG;

// positionalMapping have the property that if the content at the position they reference is deleted
// they remain valid and reference a logical content position

// positionalMapping are implemented as an array of index pointers into the array of content
// a positionalId is simply an index into an array of content positions

export function getContentPosition(positionalMapping, positionalId) {
  return positionalMapping.positions[positionalId] & MASK_OUT_DELETE_FLAG;
}

export function recordInsertContent(positionalMapping, aContentPosition) {
  const positions = positionalMapping.positions;
  for (let [positionalId, position] of positions.entries()) {
    if ((position & MASK_OUT_DELETE_FLAG) >= aContentPosition) {
      positions[positionalId] = position + 1;
    }
  }
  positions.push(aContentPosition);
  positionalMapping.contentLength++;
}

export function recordSwapsertContent(positionalMapping, aContentPosition) {
  const positions = positionalMapping.positions;
  for (let [positionalId, position] of positions.entries()) {
    if ((position & MASK_OUT_DELETE_FLAG) > aContentPosition) {
      positions[positionalId] = position + 1;
    }
  }
  positions.push(aContentPosition + 1);
  positionalMapping.contentLength++;
}

export function recordInsertAtPositionalId(positionalMapping, positionalId) {
  recordInsertContent(
    positionalMapping,
    getContentPosition(positionalMapping, positionalId)
  );
}

export function recordRemoveContent(positionalMapping, aContentPosition) {
  const positions = positionalMapping.positions;
  for (let [positionalId, position] of positions.entries()) {
    // TODO simplify
    if ((position & MASK_OUT_DELETE_FLAG) > aContentPosition) {
      positions[positionalId] = position - 1;
    } else if (position === aContentPosition) {
      // TODO fix?
      positions[positionalId] = aContentPosition | SHIFTED_BY_DELETE_FLAG;
    }
  }
  positionalMapping.contentLength--;
}

export function recordRemoveAtPositionalId(positionalMapping, positionalId) {
  recordRemoveContent(
    positionalMapping,
    getContentPosition(positionalMapping, positionalId)
  );
}

// REPLACE IS NOOP

export function initializePositionalMapping(contentArray) {
  const contentCount = contentArray.length;
  const positions = new Array(contentCount + 1);
  for (let [positionalId, _] of positions.entries()) {
    positions[positionalId] = positionalId;
  }
  return {contentLength: contentCount, positions};
}

export function makeContentToPositionalIdMapping(positionalMapping) {
  const result = new Array(positionalMapping.contentLength + 1).fill(0);
  const positions = positionalMapping.positions;
  for (let [positionalId, position] of positions.entries()) {
    if (!(position & SHIFTED_BY_DELETE_FLAG)) {
      result[position] = positionalId;
    }
  }
  return result;
}

export function adaptContentDimensionedArray(
  oldArray,
  oldPositionalMapping,
  newPositionalMapping,
  fillValue = null
) {
  const result = new Array(newPositionalMapping.contentLength + 1).fill(
    fillValue
  );
  const oldPositions = oldPositionalMapping.positions;
  const oldMaxPositionalId = oldPositions.length - 1;
  for (let i = 0; i <= oldMaxPositionalId; i++) {
    const positionalId = i;
    const oldPosition = getContentPosition(oldPositionalMapping, positionalId);
    const newPosition = getContentPosition(newPositionalMapping, positionalId);
    result[newPosition] = oldArray[oldPosition];
  }
  return result;
}

export function forEachPositionalId(positionalMapping, f) {
  const positions = positionalMapping.positions;
  for (let [positionalId, position] of positions.entries()) {
    f(positionalId);
  }
}

export function copyPositionalMapping(positionalMapping) {
  return {
    positions: positionalMapping.positions.slice(),
    contentLength: positionalMapping.contentLength
  };
}


export class PositionalMapping {
  mapping = null;
  _indexToId = null;

  constructor(mapping) {
    this.mapping = mapping;
  }

  get indexToId() {
    if (!this._indexToId) {
      this._indexToId = makeContentToPositionalIdMapping(this.mapping);
    }
    return this._indexToId;
  }

  getIndex(id) {
    return getContentPosition(this.mapping, id);
  }

  getId(index) {
    return this.indexToId[index];
  }

  idToIndexRange(range) {
    if (!range) {
      return range;
    }
    let start = getContentPosition(this.mapping, range.start);
    let end = getContentPosition(this.mapping, range.end);
    [start, end] = start < end ? [start, end] : [end, start];
    return {start, end};
  }

  forEachIdInRange(range, f) {
    // TODO decide if range non-inclusive end
    if (!range) {
      return range;
    }
    range = this.idToIndexRange(range);
    const end = range.end;
    for (let index = range.start; index <= end; index++) {
      const entityId = this.getId(index);
      f(entityId);
    }
  }
}

