import {observer} from "mobx-react";
import {expr} from "mobx-utils";
import React from "react";
import {computed, observable, reaction} from "mobx";
import Keyboardist from "keyboardist";
import {firebaseAuth, signInWithGoogle} from "../app/firebase.init";
import {isRooted, searchEntityByType} from "../app/entity-utils";
import {ScriptLineEditor} from "./script-line-editor";
import {warning} from "../lib/warn";
import {
  alerts,
  discussionsModel,
  scriptModel, setScriptEditorInstance,
  translationActions,
  wordGroupActions, wordGroupModel,
  wordSelection
} from "../app/app-root";
import {Sentence} from "./sentence";
import {openTestingModal} from "./testing-modal";
import {openWordGroupTasksSheetDialog} from "./word-group-tasks-sheet-dialog";
import {typeConfigurations} from "../app/entity-type-config";
import {TextEditor} from "./text-editor";


let domInnerTextBuffer = null;

export function textToHtml(text) {
  domInnerTextBuffer.innerText = text;
  return domInnerTextBuffer.innerHTML;
}

const ScriptLineContainer = observer((props) => {
  const {
    entity,
    getClassName,
    children,
    onClick,
    onDoubleClick,
    id
  } = props;
  const className = expr(() => getClassName(entity.id));
  return (
    <div className={className} onClick={onClick} onDoubleClick={onDoubleClick} id={id}>
      {children}
    </div>
  )
});

function getScreenPlacement(entity) {
  const node = document.getElementById(entity.id);
  if (!node) {
    return null;
  }
  const rect = node.getBoundingClientRect();
  if (rect.top < 0) {
    return 'ABOVE_SCREEN';
  }
  // TODO this can't be accurate for the parent container bounds, is for whole screen bounds?
  if (rect.bottom > window.innerHeight) {
    return 'BELOW_SCREEN';
  }
  return 'ON_SCREEN';
}

const structureLevels = ['PASSAGE_HINT', 'CHAPTER_TITLE'];

class ScriptEditorImpl extends React.Component {
  editorModel;
  @observable selectedId = null;
  // TODO support collection of subjects not only one
  @observable subjectId = null;
  @observable collapsed = false;
  @observable roughScroll = 0;
  @observable showTranslations = true;
  @observable verbatimEdit = false;
  @observable kbDeactivated = false;
  scrollingDivEl;
  actionKeyboardist = null;
  editingKeyboardist = null;
  activeEntityEditor = null;
  disposers = [];

  constructor(props) {
    super(props);
    setScriptEditorInstance(this);
    this.editorModel = scriptModel;
    // typeConfigurations['SENTENCE'].prefix = this.sentenceLinePrefix;
    // TODO take this out
    if (this.entities.length > 0) {
      this.selectedId = this.entities[0].id;
    } else {
      this.selectedId = null;
    }
    this.disposers.push(
      reaction(
        () => this.selectedId,
        () => this.handleSelectedChange(),
      ),
      reaction(
        () => this.currentChapterTitle,
        (title) => {
          this.editorModel.setCurrentChapterTitle(title)
        }
      ),
      reaction(
        () => this.mode,
        () => this.adjustControlsForMode()
      )
    );
    this.handleEnter = this.handleEnter.bind(this);
    window.document.addEventListener('keydown', this.handleEnter, false);
    this.initializeKeyboardists();
  }

  get entities() {
    return this.editorModel.orderedEntities;
  }

  get translations() {
    return this.editorModel.entityTranslations;
  }

  get discussions() {
    return discussionsModel.entityIdToActiveDiscussion;
  }

  get editingId() {
    return this.editorModel.editingId;
  }

  get isEditing() {
    return !!this.editingId;
  }

  createEntity(type) {
    if (!this.selectedId || !this.editorModel.editingEnabled) {
      warning();
      return;
    }
    this.selectedId = this.editorModel.createAnchoredEntityThenEdit(type, this.selectedId);
  }

  get editingEntity() {
    if (!this.editingId) {
      return null;
    }
    return this.entities[this.idToIndex[this.editingId]]
  }

  saveEdit() {
    if (!this.editingId || !this.activeEntityEditor) {
      warning();
      return;
    }
    // TODO wrong method name
    const content = this.activeEntityEditor.getContentFromEditor();
    this.editorModel.updateEntityContent(this.editingEntity, content);
    this.editorModel.setEditing(null);
    this.selectedId = null;
    this.verbatimEdit = false;
  }

  abortEdit() {
    if (!this.editingId || !this.activeEntityEditor) {
      warning();
      return;
    }
    this.activeEntityEditor.revert();
    this.editorModel.setEditing(null);
    this.verbatimEdit = false;
  }

  // TODO remove duplication
  splitSentenceBelow() {
    if (this.editingId || !this.selected || !this.editorModel.editingEnabled) {
      warning();
      return;
    }
    const sentence = this.selected;
    const wordId = wordSelection.getCurrentWordId();
    if (!wordId || sentence.type !== 'SENTENCE') {
      warning();
      return;
    }
    this.editorModel.splitSentenceBelow(sentence, wordId);
  }

  splitSentenceAbove() {
    if (this.editingId || !this.selected || !this.editorModel.editingEnabled) {
      warning();
      return;
    }
    const sentence = this.selected;
    const wordId = wordSelection.getCurrentWordId();
    if (!wordId || sentence.type !== 'SENTENCE') {
      warning();
      return;
    }
    this.editorModel.splitSentenceAbove(sentence, wordId);
  }


  // deleteSentence() {
  //   if (this.editingId || !this.selected || this.selected.type !== 'SENTENCE') {
  //     warning();
  //     return;
  //   }
  //   const sentence = this.selected;
  //   this.selectedId = null;
  //   this.editorModel.deleteSentence(sentence);
  // }

  entityForId(id) {
    return this.entities[this.idToIndex[id]];
  }

  setSubjectId(id) {
    if (isRooted(this.entityForId(id))) {
      warning();
      return;
    }
    this.subjectId = id;
  }

  get subject() {
    if (!this.subjectId) {
      return null;
    }
    return this.entities[this.idToIndex[this.subjectId]]
  }

  get selected() {
    if (!this.selectedId) {
      return null;
    }
    return this.entities[this.idToIndex[this.selectedId]]
  }

  reanchorSubjectEntity() {
    if (!this.subjectId || !this.selectedId || !this.editorModel.editingEnabled) {
      warning();
      return;
    }
    // TODO decide if both should be ids or both should be entities or mixed like this?
    this.editorModel.reanchorEntity(this.subject, this.selectedId);
    this.subjectId = null;
    // TODO this is just here to avoid scroll shift bug, fix that and take out
    this.selectedId = null;
  }

  editTranslation() {
    if (!this.selectedId || !this.editorModel.editingEnabled) {
      warning();
      return;
    }
    translationActions.translationDialogAction(this.selected, this.translations[this.selectedId]);
  }

  removeEntity() {
    if (!this.selectedId || this.editingId || !this.editorModel.editingEnabled) {
      warning();
      return;
    }
    if (wordGroupActions.removeFocusedWordGroup()) {
      return;
    }
    if (this.selected.type === 'SENTENCE') {
      this.editorModel.deleteSentence(this.selected);
    } else {
      this.editorModel.removeAnchoredEntity(this.selected);
    }
    this.selectedId = null;
  }

  toggleTranslations() {
    this.showTranslations = !this.showTranslations;
  }

  showCharacterCount() {
    if (!this.selected) {
      warning();
      return;
    }
    window.alert(`Character count:${this.selected.content.length}`);
  }

  toggleVerbatimEdit() {
    this.verbatimEdit = !this.verbatimEdit;
  }

  forwardWordGroupUnfilled() {
    const wordIndex = wordSelection.getCurrentWordIndex();
    const f = (r) => !r.filled && r.activated;
    const wordGroupRegionIndex = wordGroupModel.findForward(wordIndex, f);
    this.gotoWordGroupIndex(wordGroupRegionIndex);
  }

  reverseWordGroupUnfilled() {
    const wordIndex = wordSelection.getCurrentWordIndex();
    const f = (r) => !r.filled && r.activated;
    const wordGroupRegionIndex = wordGroupModel.findReverse(wordIndex, f);
    this.gotoWordGroupIndex(wordGroupRegionIndex);
  }

  forwardWordGroupType(type) {
    const wordIndex = wordSelection.getCurrentWordIndex();
    const f = type ? (r) => r.group_type === type && r.activated: (r) => r.activated;
    const wordGroupRegionIndex = wordGroupModel.findForward(wordIndex, f);
    this.gotoWordGroupIndex(wordGroupRegionIndex);
  }

  reverseWordGroupType(type) {
    const wordIndex = wordSelection.getCurrentWordIndex();
    const f = type ? (r) => r.group_type === type && r.activated: (r) => r.activated;
    const wordGroupRegionIndex = wordGroupModel.findReverse(wordIndex, f);
    this.gotoWordGroupIndex(wordGroupRegionIndex);
  }

  getFocusedWordGroupType() {
    const currentGroup = wordGroupModel.focusedWordGroupRegion;
    if (!currentGroup) {
      return null;
    }
    return currentGroup.group_type;
  }

  forwardWordGroupCurrentType() {
    this.forwardWordGroupType(this.getFocusedWordGroupType());
  }

  reverseWordGroupCurrentType() {
    this.reverseWordGroupType(this.getFocusedWordGroupType());
  }

  gotoWordIndex(index) {
    if (this.verbatimEdit) {
      return;
    }
    const sentenceIndex = scriptModel.transcriptEntitiesWordIntervals.containing(index);
    const sentenceId = scriptModel.transcriptRootedEntities[sentenceIndex].id;
    this.selectedId = sentenceId;
    wordSelection.setCurrentWordIndex(index);
  }

  gotoWordGroupIndex(index) {
    if (index === -1) {
      warning();
      return;
    }
    const {start} = wordGroupModel.regionWordIntervals.at(index);
    this.gotoWordIndex(start);
  }

  get mode() {
    if (this.kbDeactivated) {
      return 'DEACTIVATED';
    }
    if (this.isEditing) {
      return 'EDITING';
    }
    return 'ACTION';
  }

  adjustControlsForMode() {
    const mode = this.mode;
    if (mode === 'ACTION') {
      this.actionKeyboardist.startListening();
    } else {
      this.actionKeyboardist.stopListening();
    }

    if (mode === 'EDITING') {
      this.editingKeyboardist.startListening();
    } else {
      this.editingKeyboardist.stopListening();
    }
  }

  deactivateKB() {
    this.kbDeactivated = true;
  }

  activateKB() {
    this.kbDeactivated = false;
  }

  handleSelectedChange() {
    const selectedEntity = this.selected;
    if (selectedEntity) {
      if (selectedEntity.type === 'SENTENCE') {
        const wordIndex = wordSelection.getCurrentWordIndex();
        if (wordIndex > 0) {
          let selectedWordSentence = scriptModel.getSentenceContainingIndex(wordIndex);
          if (selectedWordSentence !== selectedEntity) {
            wordSelection.cancelWordRangeSelection();
          }
        }
      } else {
        wordSelection.cancelWordRangeSelection();
      }
    }
    this.scrollSelectedIntoView('nearest');
    if (this.editingId && this.editingId !== this.selectedId) {
      if (this.verbatimEdit) {
        const entity = this.entityForId(this.editingId);
        if (isRooted(entity)) {
          warning();
          this.selectedId = this.editingId;
          return;
        }
      }
      this.saveEdit();
    }
    // TODO instead of controlling other models from here set on app-root and then other models observe
    discussionsModel.setFocused(this.selectedId);
  }

  handleEnter(event) {
    if (event.code !== 'Enter' || !this.editingId) {
      return;
    }
    // if (this.verbatimEdit) {
    //   event.preventDefault();
    //   const entity = this.entityForId(this.editingId);
    //   if (isRooted(entity)) {
    //     warning();
    //     return false;
    //   }
    // }
    // TODO something better here
    if (typeConfigurations[this.selected.type]?.editorClass === TextEditor ){
      return true;
    }
    event.preventDefault();
    this.saveEdit();
    return false;
  }

  ___handleEnterInEdit() {
    if (this.verbatimEdit) {
      const entity = this.entityForId(this.editingId);
      if (isRooted(entity)) {
        warning();
        return false;
      }
    }
    // TODO something better here
    if (typeConfigurations[this.selected.type]?.editorClass === TextEditor ){
      return true;
    }
    this.saveEdit();
    return false;
  }

  scrollSelectedIntoView(placement) {
    if (!this.selectedId) {
      return;
    }
    this.scrollIntoView(this.selectedId, placement)
  }

  scrollIntoView(id, placement) {
    const element = document.getElementById(this.selectedId);
    if (!element) {
      return;
    }
    element.scrollIntoView({block: placement});
  }

  cancelSelections() {
    this.subjectId = null;
    wordSelection.cancelWordRangeSelection();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.selectedId) {
      if (getScreenPlacement(this.selected) !== 'ON_SCREEN') {
        this.scrollIntoView(this.selectedId, 'center');
      }
    }
  }

  get idToIndex() {
    return this.editorModel.entityIdToIndex;
  }

  initializeKeyboardists() {
    let kb = Keyboardist();
    kb.subscribe('Up', () => this.moveUp(0));
    kb.subscribe('KeyK', () => this.moveUp(0));
    kb.subscribe('Down', () => this.moveDown(0));
    kb.subscribe('KeyJ', () => this.moveDown(0));
    kb.subscribe('Shift+Up', () => this.moveUp(1));
    kb.subscribe('Shift+KeyK', () => this.moveUp(1));
    kb.subscribe('Shift+Down', () => this.moveDown(1));
    kb.subscribe('Shift+KeyJ', () => this.moveDown(1));
    kb.subscribe('Ctrl+Up', () => this.moveUp(2));
    kb.subscribe('Ctrl+KeyK', () => this.moveUp(2));
    kb.subscribe('Ctrl+Down', () => this.moveDown(2));
    kb.subscribe('Ctrl+KeyJ', () => this.moveDown(2));
    kb.subscribe('Enter', () => this.editSelected());
    kb.subscribe('Ctrl+Enter', () => this.editVerbatimSelected());
    kb.subscribe('Meta+Enter', () => this.editVerbatimSelected());
    kb.subscribe('KeyX', () => this.toggleCollapsed());
    kb.subscribe('KeyA', () => warning());
    kb.subscribe('Escape', () => this.cancelSelections());
    kb.subscribe('KeyM', () => this.reanchorSubjectEntity());
    kb.subscribe('Ctrl+KeyM', () => this.setSubjectId(this.selectedId));
    kb.subscribe('Meta+KeyM',  () => this.setSubjectId(this.selectedId));
    kb.subscribe('Shift+KeyC', () => this.createEntity('CHAPTER_TITLE'));
    kb.subscribe('Shift+KeyH', () => this.createEntity('PASSAGE_HINT'));
    kb.subscribe('Shift+KeyL', () => this.createEntity('SPEAKER_LABEL'));
    kb.subscribe('Shift+KeyB', () => this.createEntity('PARAGRAPH_BREAK'));
    kb.subscribe('Shift+KeyN', () => this.createEntity('CULTURAL_NOTE'));
    kb.subscribe('Shift+Period', () => this.createEntity('CHAPTER_COMPLETE'));
    kb.subscribe('Shift+KeyZ', () => this.createEntity('SILENCE_MARKER'));
    kb.subscribe('Ctrl+KeyY', () => this.editTranslation());
    kb.subscribe('Meta+KeyY', () => this.editTranslation());
    // kb.subscribe('Alt+KeyV', () => this.toggleVerbatimEdit());
    // kb.subscribe('KeyV', () => wordGroupActions.wordGroupDialogAction('vocab'));
    // kb.subscribe('KeyT', () => wordGroupActions.wordGroupDialogAction('tricky'));
    // kb.subscribe('KeyS', () => wordGroupActions.wordGroupDialogAction('sic'));
    kb.subscribe('KeyW', () => openWordGroupTasksSheetDialog());
    kb.subscribe('Shift+KeyV', () => wordGroupActions.wordGroupHighlightAction('vocab'));
    kb.subscribe('Shift+KeyT', () => wordGroupActions.wordGroupHighlightAction('tricky'));
    kb.subscribe('Shift+KeyS', () => wordGroupActions.wordGroupHighlightAction('sic'));
    kb.subscribe('KeyY', () => this.toggleTranslations());
    kb.subscribe('Shift+Digit3', () => this.showCharacterCount());
    kb.subscribe('Ctrl+KeyD', () => this.removeEntity());
    kb.subscribe('Meta+KeyD', () => this.removeEntity());
    kb.subscribe('Alt+Right', () => this.forwardWordGroupUnfilled());
    kb.subscribe('Alt+Left', () => this.reverseWordGroupUnfilled());
    kb.subscribe('Meta+Right', () => this.forwardWordGroupUnfilled());
    kb.subscribe('Meta+Left', () => this.reverseWordGroupUnfilled());
    kb.subscribe('Shift+Right', () => this.forwardWordGroupCurrentType());
    kb.subscribe('Shift+Left', () => this.reverseWordGroupCurrentType());
    kb.subscribe('Right', () => this.forwardWordGroupType(null));
    kb.subscribe('Left', () => this.reverseWordGroupType(null));
    kb.subscribe('BracketLeft', () => this.moveNextOpenDiscussion());
    kb.subscribe('Shift+Digit6', () => this.splitSentenceBelow());
    kb.subscribe('Shift+Ctrl+Digit6', () => this.splitSentenceAbove());
    // kb.subscribe('Alt+Shift+Ctrl+KeyD', () => this.deleteSentence());
    kb.subscribe('Digit0', () => openTestingModal());
    kb.subscribe('Digit2', () => {
      openWordGroupTasksSheetDialog();
    });
    this.actionKeyboardist = kb;

    kb = Keyboardist();
    kb.subscribe('Ctrl+KeyS', () => this.saveEdit());
    kb.subscribe('Meta+KeyS', () => this.saveEdit());
    // kb.subscribe('Enter', () => this.handleEnterInEdit());
    kb.subscribe('Escape', () => {
      if (!this.isEditing) {
        alerts.doAlert({bell: true});
      } else {
        this.abortEdit();
      }
    });
    this.editingKeyboardist = kb;
    this.adjustControlsForMode();
  }

  moveBy(offset) {
    if (!this.selectedId) {
      return;
    }
    let index = this.idToIndex[this.selectedId];
    index += offset;
    const compare = index;
    if (index < 0) {
      index = 0;
    }
    if (index >= this.entities.length) {
      index = this.entities.length - 1;
    }
    if (index !== compare) {
      alerts.doAlert({bell: true});
    }
    this.selectedId = this.entities[index].id;
  }

  searchMove(search, direction) {
    if (!this.selectedId) {
      return;
    }
    const type = structureLevels[search - 1];
    const start = this.idToIndex[this.selectedId] + direction;
    // TODO change to search type list not single type
    const index = searchEntityByType(this.entities, start, [type], direction);
    if (index !== -1) {
      this.selectedId = this.entities[index].id;
      const placement = this.collapsed ? 'nearest' : 'center';
      this.scrollSelectedIntoView(placement);
    } else {
      alerts.doAlert({bell: true})
    }
  }


  // TODO factor these two functions
  moveUp(search) {
    const direction = -1;
    if (this.collapsed) {
      search = search + 1;
      const max = structureLevels.length;
      search = (search > max) ? max : search;
    }
    if (search) {
      this.searchMove(search, direction);
    } else {
      this.moveBy(direction);
    }
  }

  moveDown(search) {
    const direction = 1;
    if (this.collapsed) {
      search = search + 1;
      const max = structureLevels.length;
      search = (search > max) ? max : search;
    }
    if (search) {
      this.searchMove(search, direction);
    } else {
      this.moveBy(direction);
    }
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  moveNextOpenDiscussion() {
    if (!this.selectedId) {
      return;
    }
    let index = this.idToIndex[this.selectedId] + 1;
    const len = this.entities.length;
    while (index < len && index >= 0) {
      if (this.discussions[this.entities[index].id]) {
        break;
      }
      index += 1;
    }
    if (index !== len) {
      this.selectedId = this.entities[index].id;
      const placement = this.collapsed ? 'nearest' : 'center';
      this.scrollSelectedIntoView(placement);
    } else {
      alerts.doAlert({bell: true})
    }
  }

  editSelected() {
    if (this.selectedId) {
      if (!this.verbatimEdit && isRooted(this.selected)) {
        warning();
        return;
      }
      this.editorModel.setEditing(this.selectedId);
    }
  }

  editVerbatimSelected() {
    if (!this.selectedId || !isRooted(this.selected)) {
      warning();
      return;
    }
    this.verbatimEdit = true;
    this.editorModel.setEditing(this.selectedId);
  }

  // finishEdit() {
  //   this.editorModel.setEditing(null);
  // }

  @computed
  get chapterTitleEntities() {
    return this.entities.filter((entity) => entity.type === 'CHAPTER_TITLE');
  }

  handleScroll(e) {
    this.roughScroll = e.target.scrollTop % 20;
  }

  @computed
  get currentChapterTitle() {
    let last = null;
    const touch = this.roughScroll;
    for (const entity of this.chapterTitleEntities) {
      const placement = getScreenPlacement(entity);
      if (placement === 'BELOW_SCREEN') {
        break;
      }
      last = entity;
    }
    if (!last || !last.content) {
      return "";
    }
    return last.content;
  }

  componentWillUnmount() {
    setScriptEditorInstance(null);
    for (const disposer of this.disposers) {
      disposer();
    }
    this.disposers = [];
  }

  handleLineClick(id) {
    this.selectedId = id;
  }

  handleLineDoubleClick(id) {
    if (!this.verbatimEdit && isRooted(this.entityForId(id))) {
        warning();
        return;
    }
    this.editorModel.setEditing(id);
  }

  // TODO actually these are just from props?
  pushBuffer = () => null;
  popBuffer = () => null;
  getIsEditing = (id) => {
    return this.editorModel.editingId === id;
  };

  getLineClassName = (id) => {
    const activeLineClass = (this.verbatimEdit && isRooted(this.entityForId(id))) ? 'verbatim-active-line' : 'active-line';
    let className = (id === this.selectedId) ? 'editor-line ' +  activeLineClass: 'editor-line';
    if (id === this.subjectId) {
      className += ' subject-line';
    }
    if (this.discussions[id]) {
      className += ' has-discussion';
    }
    return className;
  };

  setActiveEntityEditor = (editor, unset = false) => {
    if (unset) {
      if (this.activeEntityEditor === editor) {
        this.activeEntityEditor = null;
      }
    } else {
      this.activeEntityEditor = editor;
    }
  };

  LineEditor = ({entity, translation}) => {
    return(
      <ScriptLineEditor
        entity={entity}
        translation={translation}
        pushBuffer={this.pushBuffer}
        popBuffer={this.popBuffer}
        setActive={this.setActiveEntityEditor}
        getIsEditing={this.getIsEditing}
      />);
  };

  SentenceEditor = ({entity, translation}) => {
    return(
      <Sentence
        entity={entity}
        translation={translation}
      />);
  };

  getLineEditorComponent = (entity) => {
    if (entity.type === 'SENTENCE') {
      return (this.verbatimEdit && this.selected === entity) ? this.LineEditor : this.SentenceEditor;
    }
    return this.LineEditor;
  };



  Line = ({entity, translation}) => {
    // TODO PASS in scriptlineeditor component, create that component with different local HOCs
    //  TODO hoc takes entity + translation, HOC closure fills in other props as appropriate for the type
    // TODO different HOC is used for sentences in verbatim edit mode

    // TODO Inner = this.getXXXX
    const Editor = this.getLineEditorComponent(entity);
    return (
      <ScriptLineContainer
        id={entity.id}
        entity={entity}
        getClassName={this.getLineClassName}
        onClick={() => this.handleLineClick(entity.id)}
        onDoubleClick={() => this.handleLineDoubleClick(entity.id)}
      >
        <Editor
          entity={entity}
          translation={translation}
        />
      </ScriptLineContainer>
    );
  };

  render() {
    const Line = this.Line;
    let entities = this.entities;
    if (this.collapsed) {
      entities = entities.filter((entity) => structureLevels.indexOf(entity.type) >= 0)
    }

    const showTranslation = this.showTranslations;
    const translations = this.translations;
    const touchVerbatimEdit = this.verbatimEdit;

    return (
      <div
        className={'editor'}
        ref={(element) => this.scrollingDivEl = element}
        onScroll={(e) => this.handleScroll(e)}
      >

        <div>
          <div ref={(element) => domInnerTextBuffer = element} style={{display: 'none'}}/>
          {entities.map((entity) =>
              <Line
                entity={entity}
                key={entity.id + entity.content}
                translation={(showTranslation) ? translations[entity.id] : null}
              />
          )}
        </div>
      </div>
    );
  }
}

export const ScriptEditor = observer(ScriptEditorImpl);
