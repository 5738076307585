import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";


export function initFirebase() {
  const config = {
    apiKey: "AIzaSyCk7qf6Qbsq2oRwCel1g08st-su4pYDB_0",
    authDomain: "jiveworld.firebaseapp.com",
    databaseURL: "https://jiveworld.firebaseio.com",
    projectId: "jiveworld",
    storageBucket: "jiveworld.appspot.com",
    messagingSenderId: "903123262392",
    appId: "1:903123262392:web:3daaccb9ba95b3e4cf0d4d"
  };
  firebase.initializeApp(config);
  firebase.firestore().enablePersistence()
    .catch(function(err) {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });

}

initFirebase();

export const db = firebase.firestore();
export const firebaseAuth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => firebaseAuth.signInWithPopup(provider);

