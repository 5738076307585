import {TextEditor} from "../components/text-editor";

// TODO more descriptive definition TranscriptRooted, NonTranscriptRooted array and flatten to anyRooted
// TODO anyRooted should not allow SCRIPT_BEGIN, SCRIPT_END
const anyRooted = ['SENTENCE'];
// TODO not exactly right
const anyAnchored = ['CHAPTER_TITLE', 'PASSAGE_HINT'];
export const anchorTypeInfo = {
  SPEAKER_LABEL: {
    precedence: 1,
    before: anyRooted,
  },
  PASSAGE_HINT: {
    precedence: 2,
    before: anyRooted,
  },
  CHAPTER_TITLE: {
    precedence: 5,
    before: anyRooted,
  },
  SILENCE_MARKER: {
    precedence: 2,
    after: anyRooted,
  },
  CHAPTER_COMPLETE: {
    precedence: 1,
    after: anyRooted,
  },
  PARAGRAPH_BREAK: {
    precedence: 3,
    after: anyRooted,
  },
  CHAPTER_SUMMARY: {
    precedence: 4,
    before: anyRooted,
  },
  CULTURAL_NOTE: {
    precedence: 3,
    before: anyRooted,
  },
  METADATA_URL: {
    precedence: 1,
    after: ['SCRIPT_BEGIN'],
  },
  NOTES: {
    precedence: 2,
    after: ['SCRIPT_BEGIN'],
  },
  METADATA: {
    precedence: 3,
    after: ['SCRIPT_BEGIN'],
  },
  CAST: {
    precedence: 4,
    after: ['SCRIPT_BEGIN'],
  },
  ASSET_LINKS: {
    precedence: 5,
    after: ['SCRIPT_BEGIN'],
  },
};

export const groupMembershipType = {
  vocab: {
    className: 'vocab'
  },
  tricky: {
    className: 'tricky'
  },
  sic: {
    className: 'sic'
  },
  overlapping: {
    className: 'word-group-type-overlapping'
  }
};

// TODO fill in editorClass for all instead?
export const typeConfigurations = {
  SENTENCE: {
    prefix: null,
    containerClassName: 'sentence',
  },
  CHAPTER_TITLE: {
    prefix: '# ',
    containerClassName: 'chapter-title',
  },
  SPEAKER_LABEL: {
    prefix: '@',
    containerClassName: 'speaker-label',
  },
  PASSAGE_HINT: {
    prefix: '## ',
    containerClassName: 'passage-hint',
  },
  CHAPTER_COMPLETE: {
    prefix: '//! CHAPTER-COMPLETE',
    containerClassName: 'chapter-complete',
  },
  SILENCE_MARKER: {
    prefix: '//! SILENCE ',
    containerClassName: 'silence-marker',
  },
  PARAGRAPH_BREAK: {
    prefix: '### ',
    containerClassName: 'paragraph-break',
  },
  CHAPTER_SUMMARY: {
    prefix: '%% ',
    containerClassName: 'chapter-summary',
  },
  CULTURAL_NOTE: {
    prefix: '% ',
    containerClassName: 'cultural-note',
    editorClass: TextEditor,
  },
  METADATA_URL: {
    prefix: null,
    containerClassName: 'metadata-block metadata-url',
    editorClass: TextEditor,
  },
  NOTES: {
    prefix: null,
    containerClassName: 'metadata-block notes',
    editorClass: TextEditor,
  },
  METADATA: {
    prefix: null,
    containerClassName: 'metadata-block metadata',
    editorClass: TextEditor,
  },
  CAST: {
    prefix: null,
    containerClassName: 'metadata-block cast',
    editorClass: TextEditor,
  },
  ASSET_LINKS: {
    prefix: null,
    containerClassName: 'metadata-block asset-links',
    editorClass: TextEditor,
  },
};

function exists(typestring) {
  return !(typestring === "undefined");
}

export function beforeOrAfter(obj) {
  if (exists(typeof obj.before)) {
    return 'before';
  }
  if (exists(typeof obj.after)) {
    return 'after';
  }
  // todo throw
}

export function getAnchorInfo(type) {
  const placement = beforeOrAfter(anchorTypeInfo[type]);
  return {placement, targetTypes: anchorTypeInfo[type][placement]};
}
