
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader
} from "mdbreact";
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { translationActions } from "../app/app-root";

class TranslationDialogState {
  @observable open = false;
  @observable translation;
  entity = {};
}

const dialogState = new TranslationDialogState();

export function openTranslationDialog(entity, translation) {
  // TODO
  dialogState.entity = entity;
  dialogState.translation = translation;
  dialogState.open = true;
}

export const TranslationDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
  };

  const handleTranslationChange = event => {
    dialogState.translation = event.target.value;
  };

  return (
    <MDBModal isOpen={dialogState.open} toggle={toggleModal}>
      <MDBModalHeader
        className="text-center"
        titleClass="w-100 font-weight-bold"
        toggle={toggleModal}
      >
        {"Edit Translation"}
      </MDBModalHeader>
      <MDBModalBody>
        <form className="mx-3">
          <div className="form-group"> <span style={{fontWeight:'bold'}}> Source Content: </span> {dialogState.entity.content}</div>
          <div className="form-group">
            <textarea
              className="form-control"
              id="translationInput"
              value={dialogState.translation}
              rows={5}
              onChange={handleTranslationChange}
            />
          </div>
        </form>
      </MDBModalBody>
      <MDBModalFooter className="justify-content-center">
            <MDBBtn
              color="info"
              onClick={() => {
                translationActions.updateTranslation(dialogState.entity.id, dialogState.translation);
                toggleModal();
              }}
            >
              Update
            </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
});


