
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader
} from "mdbreact";
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { wordGroupActions } from "../app/app-root";
import {WordGroupThread} from "./word-group-thread";


class WordGroupThreadDialogState {
  @observable open = false;
  regionId;
}

const dialogState = new WordGroupThreadDialogState();

export function openWordThreadGroupDialog(regionId) {
  // TODO
  dialogState.regionId = regionId;
  dialogState.open = true;
}

export const WordGroupThreadDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
  };


  return (
    <MDBModal isOpen={dialogState.open} toggle={toggleModal}>
      {/*<MDBModalHeader*/}
      {/*  className="text-center"*/}
      {/*  titleClass="w-100 font-weight-bold"*/}
      {/*  toggle={toggleModal}*/}
      {/*>*/}
      {/*  Word Group Thread*/}
      {/*</MDBModalHeader>*/}
      <MDBModalBody>
        <WordGroupThread regionId={dialogState.regionId}/>
      </MDBModalBody>
    </MDBModal>
  );
});
