import {computed, observable} from "mobx";
import * as firebase from "firebase/app";
import {
  anchorType, getTranscriptWords,
  getWords,
  isRooted,
  randomString,
  rootedType,
  searchEntityByType,
  strongNormalizeWordArray
} from "./entity-utils";
import {anchorTypeInfo, getAnchorInfo} from "./entity-type-config";
import {SortedIntervals} from "../lib/sorted-intervals";
import {doDiffAndPatch} from "../lib/positional-diff-patch";
import {getContentPosition, makeContentToPositionalIdMapping} from "../lib/positional-ids";
import {auditLogActions, wordSelection} from "./app-root";
import {warning} from "../lib/warn";


function exists(typestring) {
  return !(typestring === "undefined");
}

export class ScriptEditorModel {
  @observable.ref words = [];
  @observable.ref positionalMapping = {};
  @observable.ref mapping;
  @observable.ref rootedOrder = [];
  @observable.ref rootedEntitiesDict = {};
  @observable.ref anchoredEntitiesDict = {};
  @observable.ref editingId;
  @observable.ref rootOrderEditLease;
  @observable.ref entityEditLease;
  @observable.ref entityTranslations;
  @observable.ref editorState;
  // TODO maybe doesn't go here?
  @observable.ref currentChapterTitle;

  openEditChannelForId;
  editingBuffer = null;
  dbPaths = null;
  // keep on leases?
  lastEditTime = null;

  get editingEnabled() {
    return this.editorState.enabled;
  }

  setEditing(id) {
    if (!this.editingEnabled) {
      warning();
      return;
    }
    this.editingId = id;
  }

  wordIdToIndex(positionalId) {
    return getContentPosition(this.positionalMapping, positionalId);
  }

  @computed({ keepAlive: true })
  get hasTranslations() {
    if (!this.entityTranslations) {
      return false;
    }
    return Object.values(this.entityTranslations).length > 0;
  }

  // TODO analyze all these keep alives to see which are needed
  @computed({keepAlive: true})
  get orderedEntities() {
    // TODO handle entities anchored to non root entities
    const result = [];
    const entityAnchorReferenceMap = {};
    for (const entity of this.nonTranscriptRootedEntities) {
      entityAnchorReferenceMap[entity.id] = {before: [], after: []}
    }
    for (const entity of this.anchoredEntities) {
      entityAnchorReferenceMap[entity.id] = {before: [], after: []}
    }
    entityAnchorReferenceMap['SCRIPT_BEGIN'] = {before: [], after: []};
    entityAnchorReferenceMap['SCRIPT_END'] = {before: [], after: []};
    // TODO change to create arrays on first use as opposed to have a bunch of empty arrays?
    const transcriptPositionalLookup = this.transcriptRootedEntities.map(() => {
      return {before: [], after: []}
    });
    for (const anchored of this.anchoredEntities) {
      const before = exists(typeof anchored.before);
      const anchor = (before) ? anchored.before : anchored.after;
      const placement = (before) ? 'before' : 'after';
      if (anchorType(anchor) === 'ENTITY') {
        entityAnchorReferenceMap[anchor][placement].push(anchored);
      } else {
        // Positional
        let index = this.transcriptEntitiesWordIntervals.containing(this.wordIdToIndex(anchor));
        if (!before) { // after
          if (index === -1) {
            // TODO more validity checking?
            index = transcriptPositionalLookup.length - 1;
          } else {
            index--;
          }
        }
        // TODO fix -1 index so never occurs instead of ignoring
        if (index !== -1) {
          transcriptPositionalLookup[index][placement].push(anchored)
        }
      }
    }

    const beforePrecedenceFunction = (a, b) => {
      return anchorTypeInfo[b.type].precedence - anchorTypeInfo[a.type].precedence;
    };
    const afterPrecedenceFunction = (a, b) => {
      return anchorTypeInfo[a.type].precedence - anchorTypeInfo[b.type].precedence;
    };


    for (const anchors of Object.values(entityAnchorReferenceMap)) {
      anchors.before.sort(beforePrecedenceFunction);
      anchors.after.sort(afterPrecedenceFunction);
    }

    for (const anchors of transcriptPositionalLookup) {
      anchors.before.sort(beforePrecedenceFunction);
      anchors.after.sort(afterPrecedenceFunction);
    }

    function expandAnchors(entities) {
      const result = [];
      for (const entity of entities) {
        let before, after;
        ({before, after} = entityAnchorReferenceMap[entity.id]);
        result.push(...before, entity, ...after);
      }
      return result;
    }

    let before = null;
    let after;
    ({after} = entityAnchorReferenceMap['SCRIPT_BEGIN']);
    result.push(...after);
    const idToIndex = this.transcriptEntitiesIdToIndex;
    for (const root of this.rootedEntities) {
      const type = rootedType(root);
      if (type === 'NON_TRANSCRIPT') {
        ({before, after} = entityAnchorReferenceMap[root.id]);
      } else {
        const index = idToIndex[root.id];
        ({before, after} = transcriptPositionalLookup[index]);
      }
      result.push(...expandAnchors(before), root, ...expandAnchors(after));
    }
    ({before} = entityAnchorReferenceMap['SCRIPT_END']);
    result.push(...before);

    return result;
  }

  @computed({keepAlive: true})
  get entityIdToIndex() {
    const result = {};
    for (const [index, entity] of this.orderedEntities.entries()) {
      result[entity.id] = index;
    }
    return result;
  }

  @computed({keepAlive: true})
  get anchoredEntities() {
    const map =  this.anchoredEntitiesDict;
    const assertMetadata = (type) => {
      map[type] = map[type] || { type, id: type, content:'', after:'SCRIPT_BEGIN'};
    };
    // TODO optimize not call values twice
    if (Object.values(map).length > 0) {
      assertMetadata('METADATA_URL');
      // assertMetadata('NOTES');
      // assertMetadata('METADATA');
      // assertMetadata('CAST');
      // assertMetadata('ASSET_LINKS');
    }

    return Object.values(map);
  }

  @computed({keepAlive: true})
  get rootedEntities() {
    return this.rootedOrder.map((id) => this.rootedEntitiesDict[id]);
  }

  @computed({keepAlive: true})
  get transcriptRootedEntities() {
    // TODO use anchor type positional as conditional here instead
    return this.rootedEntities.filter((entity) => entity.type === 'SENTENCE');
  }

  @computed({keepAlive: true})
  get nonTranscriptRootedEntities() {
    // TODO use non anchor type positional as conditional here instead
    return this.rootedEntities.filter((entity) => entity.type !== 'SENTENCE')
  }

  @computed({keepAlive: true})
  get transcriptEntitiesWordIntervals() {
    const startPoints = [];
    const endPoints = [];
    let startWordPos = 0;
    let endWordPos;
    for (const entity of this.transcriptRootedEntities) {
      startPoints.push(startWordPos);
      //TODO think inclusive exclusive
      endWordPos = startWordPos + entity.transcriptWordCount;
      endPoints.push(endWordPos);
      startWordPos = endWordPos;
    }
    return new SortedIntervals({startPoints, endPoints});
  }

  getSentenceContainingIndex(index) {
    const sentenceIndex = this.transcriptEntitiesWordIntervals.containing(index);
    return this.transcriptRootedEntities[sentenceIndex];
  }

  @computed({keepAlive: true})
  get transcriptEntitiesIdToIndex() {
    const result = {};
    for (const [index, entity] of this.transcriptRootedEntities.entries()) {
      result[entity.id] = index;
    }
    return result;
  }

  pushBuffer(entityId, content) {
    if (this.editingId !== entityId && this.openEditChannelForId === entityId) {
      // TODO save the update to database and clear out editing buffer
      return;
    }
    if (this.editingId === entityId) {
      this.editingBuffer = {id: entityId, content};
    } else {
      this.editingBuffer = null;
    }
  }

  popBuffer(entityId) {
    const buffer = this.editingBuffer;
    this.editingBuffer = null;
    if (entityId !== this.editingId || !buffer) {
      return null;
    }
    if (buffer.id !== entityId) {
      return null;
    }
    return buffer.content;
  }

  getTranscriptEditLease() {
    // TODO should probably make some functions to get release leases

  }

  releaseTranscriptEditLease() {

  }

  getEntityEditLease(entityId) {
    // using transaction verify no selected lease then create/overwrite one

  }

  releaseEntityEditLease() {
    // first verify have the selected lease then delete it?

  }

  getAnchorFor(fromType, targetId) {
    // fromType is type of entity that will be anchored, targetId is start for anchor target search
    const {placement, targetTypes} = getAnchorInfo(fromType);
    const startIndex = this.entityIdToIndex[targetId];
    const direction = placement === 'before' ? 1 : -1;
    const targetIndex = searchEntityByType(this.orderedEntities, startIndex, targetTypes, direction);
    if (targetIndex === -1) {
      return null;
    }
    const targetEntity = this.orderedEntities[targetIndex];
    if (rootedType(targetEntity) === 'TRANSCRIPT') {
      // TODO change end_word_pos, to endWordPos, think about recalc cycle on client, calc on each load of firestore doc only stored on client?
      if (placement === 'before') {
        return {[placement]: targetEntity.start_word_pos};
      } else {
        // TODO use next word id, just increment not valid
        return {[placement]: targetEntity.end_word_pos};
      }
    }
    return {[placement] : targetEntity.id};
  }

  createAnchoredEntity(type, targetId) {
    const anchor =  this.getAnchorFor(type, targetId);
    if (!anchor) {
      return null;
    }
    const id = `${type}:${randomString(12)}`;
    const entity = {type, id, content:'', ...anchor};
    auditLogActions.addLogMessage('create', entity);
    this.addUpdateAnchoredEntity(entity);
    return id;
  }

  createAnchoredEntityThenEdit(type, targetId) {
    this.editingId = this.createAnchoredEntity(type, targetId);
    return this.editingId;
  }

  reanchorEntity(entity, targetId) {
    const anchor = this.getAnchorFor(entity.type, targetId);
    if (!anchor) {
      return false;
    }
    entity = {...entity, ...anchor};
    this.addUpdateAnchoredEntity(entity);
    auditLogActions.addLogMessage('move', entity);
    return true;
  }

  updateEntityContent(entity, content) {
    const oldEntity = entity;
    if (oldEntity.content === content) {
      // TODO consider applying check for edit save with no changes at higher level
      return;
    }
    entity = { ...entity, content};
    auditLogActions.addLogMessage('update', entity, oldEntity);
    if (isRooted(entity)) {
      if (rootedType(entity) === 'TRANSCRIPT') {
        this.updateTranscriptEntity(entity)
      } else {
        // TODO
      }
    } else {
      this.addUpdateAnchoredEntity(entity, content);
    }
    return true;
  }

  sanityCheckTranscriptUpdate(newPositionalMap, oldPositionalMap, newWords) {
    const sentences = this.transcriptRootedEntities;
    let groups = 0;
    let count = 0;
    let maxCount = 0;
    const allWords = (words) => {
      for (const word of words) {
        if (!word.trim()) {
          return false;
        }
      }
      return true;
    };

    if (allWords(this.words) !== allWords(newWords)) {
      return false;
    }

    for (const s of sentences) {
      const startWordId = s.start_word_pos;
      const oldIndex = getContentPosition(oldPositionalMap, startWordId);
      const newIndex = getContentPosition(newPositionalMap, startWordId);
      if (this.words[oldIndex] !== newWords[newIndex]) {
        if (!count) {
          groups++;
        }
        count++;
        if (count > maxCount) {
          maxCount = count;
        }
      } else {
        count = 0;
      }
    }
    return (groups <= 1 && maxCount <= 1);
  }

  updateTranscriptEntity(entity) {
    // TODO change interface and pass in current entity and new content params
    const newWords = [];
    for (const e of this.transcriptRootedEntities) {
      if (e.id === entity.id) {
        newWords.push(...getTranscriptWords(entity.content));
      } else {
        newWords.push(...getTranscriptWords(e.content));
      }
    }
    let newSentenceWords = [];
    newSentenceWords.push(...getTranscriptWords(entity.content));
    newSentenceWords = strongNormalizeWordArray(newSentenceWords);
    const oldStartIndex = getContentPosition(this.positionalMapping, entity.start_word_pos);
    const entityIndex = this.entityIdToIndex[entity.id];
    const oldEntity = this.orderedEntities[entityIndex];
    let oldSentenceWords = [];
    oldSentenceWords.push(...getTranscriptWords(oldEntity.content));
    oldSentenceWords = strongNormalizeWordArray(oldSentenceWords);
    const {newPositionalMapping} = doDiffAndPatch(
      oldStartIndex,
      oldSentenceWords,
      this.positionalMapping,
      newSentenceWords,
    );
    entity.transcriptWordCount = getTranscriptWords(entity.content).length;
    const newStartIndex = getContentPosition(newPositionalMapping, entity.start_word_pos);
    const entities = [entity];
    if (oldStartIndex !== newStartIndex) {
      const indexToId = makeContentToPositionalIdMapping(newPositionalMapping);
      entity.start_word_pos = indexToId[oldStartIndex];
      const sentenceIndex = this.transcriptEntitiesIdToIndex[entity.id];
      if (sentenceIndex > 0) {
        const previousSentence = this.transcriptRootedEntities[sentenceIndex - 1];
        previousSentence.end_word_pos = entity.start_word_pos;
        entities.push(previousSentence);
      }
    }
    // auditLogActions.addLogMessage('update', entity);
    if (!this.sanityCheckTranscriptUpdate(newPositionalMapping, this.positionalMapping, newWords)) {
      window.alert('verbatim edit creating problems with transcript aborting');
      warning();
      return;
    }
    this.updateTranscript(newWords, newPositionalMapping, entities);
  }

  async updateTranscript(words, positionalMapping, entities = null) {
    const update = {
      words,
      positionalMapping,
      version: this.getNewVersionKey()
    };
    if (entities) {
      for (const entity of entities) {
        update['entities.' + entity.id] = entity;
      }
    }
    const rootedEntitiesDocRef = this.dbPaths.rootedEntitiesDocRef;
    const result = await rootedEntitiesDocRef.update(update);
  }

  async splitSentenceAbove(sentence, wordId) {
    // TODO factor to remove some duplication with splitSentenceBelow
    const update = {};
    const sentenceIdRange = {start:wordId, end: sentence.end_word_pos};
    const sentencePath = 'entities.' + sentence.id;
    update[sentencePath + '.start_word_pos'] = wordId;
    const updateContent = wordSelection.textForIdRange(sentenceIdRange);
    update[sentencePath + '.content'] = updateContent;
    update[sentencePath + '.transcriptWordCount'] = getTranscriptWords(updateContent).length;
    const newSentenceIdRange = {start:sentence.start_word_pos, end: wordId};
    const newSentenceContent =  wordSelection.textForIdRange(newSentenceIdRange);
    const newSentenceLength = getTranscriptWords(newSentenceContent).length;
    const newSentenceId = `SENTENCE:${randomString(12)}`;
    const newSentence = {
      type:'SENTENCE',
      id: newSentenceId,
      content: newSentenceContent,
      transcriptWordCount: newSentenceLength,
      start_word_pos: sentence.start_word_pos,
      end_word_pos: wordId,
    };
    const newSentencePath = 'entities.' + newSentenceId;
    update[newSentencePath] = newSentence;
    const rootedOrder = this.rootedOrder.slice();
    const index = rootedOrder.indexOf(sentence.id);
    rootedOrder.splice(index, 0, newSentenceId);
    update['order'] = rootedOrder;
    auditLogActions.addLogMessage('split', sentence);
    const rootedEntitiesDocRef = this.dbPaths.rootedEntitiesDocRef;
    const result = await rootedEntitiesDocRef.update(update);
  }

  async splitSentenceBelow(sentence, wordId) {
    const update = {};
    const sentenceIdRange = {start:sentence.start_word_pos, end: wordId};
    const sentencePath = 'entities.' + sentence.id;
    update[sentencePath + '.end_word_pos'] = wordId;
    const updateContent = wordSelection.textForIdRange(sentenceIdRange);
    update[sentencePath + '.content'] = updateContent;
    update[sentencePath + '.transcriptWordCount'] = getTranscriptWords(updateContent).length;
    const newSentenceIdRange = {start:wordId, end: sentence.end_word_pos};
    const newSentenceContent =  wordSelection.textForIdRange(newSentenceIdRange);
    const newSentenceLength = getTranscriptWords(newSentenceContent).length;
    const newSentenceId = `SENTENCE:${randomString(12)}`;
    const newSentence = {
      type:'SENTENCE',
      id: newSentenceId,
      content: newSentenceContent,
      transcriptWordCount: newSentenceLength,
      start_word_pos: wordId,
      end_word_pos: sentence.end_word_pos,
    };
    const newSentencePath = 'entities.' + newSentenceId;
    update[newSentencePath] = newSentence;
    const rootedOrder = this.rootedOrder.slice();
    const index = rootedOrder.indexOf(sentence.id) + 1;
    rootedOrder.splice(index, 0, newSentenceId);
    update['order'] = rootedOrder;
    auditLogActions.addLogMessage('split', sentence);
    const rootedEntitiesDocRef = this.dbPaths.rootedEntitiesDocRef;
    const result = await rootedEntitiesDocRef.update(update);
  }

  async deleteSentence(sentence) {
    // TODO sentence must have no words to delete
    if (sentence.transcriptWordCount > 0) {
      warning();
      return;
    }
    const update = {
      [sentence.id]: firebase.firestore.FieldValue.delete()
    };
    const rootedOrder = this.rootedOrder.slice();
    const index = rootedOrder.indexOf(sentence.id);
    rootedOrder.splice(index, 1);
    update['order'] = rootedOrder;
    const rootedEntitiesDocRef = this.dbPaths.rootedEntitiesDocRef;
    const result = await rootedEntitiesDocRef.update(update);
  }

  // TODO should allow partial update? is there a difference? if tracking version diffs here there is
  // TODO different version for rooted?
  async addUpdateAnchoredEntity(entity) {
    const entityWrapper = {};
    entityWrapper[entity.id] = entity;
    const anchoredEntitiesDocRef = this.dbPaths.anchoredEntitiesDocRef;
    const result = await anchoredEntitiesDocRef.update(entityWrapper);
  }

  async removeAnchoredEntity(entity) {
    const {id} = entity;
    if (isRooted(entity)) {
      warning();
      return;
    }
    auditLogActions.addLogMessage('delete', entity);
    const anchoredEntitiesDocRef = this.dbPaths.anchoredEntitiesDocRef;
    const result = await anchoredEntitiesDocRef.update({
      [id]: firebase.firestore.FieldValue.delete()
    });
  }

  setCurrentChapterTitle(title) {
    this.currentChapterTitle = title
  }

  getNewVersionKey() {
    return Math.round(Date.now() / 1000)
  }
}
