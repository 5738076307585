
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader
} from "mdbreact";
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { wordGroupActions } from "../app/app-root";

class WordGroupDialogState {
  @observable open = false;
  @observable group_type;
  @observable note;
  @observable commentText;
  regionId;
  id;
  text;
  startPosition;
  endPosition;
}

const dialogState = new WordGroupDialogState();

export function openWordGroupDialog(regionId, wordGroupData) {
  // TODO
  dialogState.regionId = regionId;
  dialogState.id = wordGroupData.id;
  dialogState.text = wordGroupData.text;
  console.log("SETTING VOCAB TEXT: " + wordGroupData.text);
  dialogState.group_type = wordGroupData.group_type;
  dialogState.note = wordGroupData.note ? wordGroupData.note :'';
  dialogState.commentText = '';
  dialogState.startPosition = wordGroupData.startPosition;
  dialogState.endPosition = wordGroupData.endPosition;
  dialogState.open = true;
}

const groupTypeToNoteName = {
  vocab: 'Definition',
  tricky: null,
  sic: 'Note',
};

export const WordGroupDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
  };

  const handleTypeChange = event => {
    dialogState.group_type = event.target.value;
  };

  const handleNoteChange = event => {
    dialogState.note = event.target.value;
  };

  const handleCommentTextChange = event => {
    dialogState.commentText = event.target.value;
  };

  const getEditedState = () => {
    return {
      group_type: dialogState.group_type,
      note: dialogState.note,
      comment: dialogState.commentText,
      startPosition: dialogState.startPosition,
      endPosition: dialogState.endPosition
    };
  };

  const noteName = groupTypeToNoteName[dialogState.group_type];
  return (
    <MDBModal isOpen={dialogState.open} toggle={toggleModal}>
      <MDBModalHeader
        className="text-center"
        titleClass="w-100 font-weight-bold"
        toggle={toggleModal}
      >
        {dialogState.id ? "Update Word Group" : "Add Word Group"}
      </MDBModalHeader>
      <MDBModalBody>
        <form className="mx-3">
          <div className="form-group">Words: {dialogState.text}</div>
          <div className="form-group">
            <select
              value={dialogState.group_type}
              className="browser-default custom-select"
              onChange={handleTypeChange}
              disabled={!!dialogState.id}
            >
              <option>Group Type</option>
              <option value="vocab">Vocab</option>
              <option value="tricky">Tricky Bit</option>
              <option value="sic">Sic</option>
            </select>
          </div>
          {noteName ?
            <div className="form-group">
              <label htmlFor="noteInput">{noteName}</label>
              <input
                type="text"
                className="form-control"
                id="noteInput"
                value={dialogState.note}
                onChange={handleNoteChange}
              />
              {/*<MDBInput label="Definition"  group value={dialogState.note}/>*/}
            </div>
            : ''
          }
          <div className="form-group">
            <textarea
              style={{width: '100%'}}
              onChange={(e) => handleCommentTextChange(e)}
              value={dialogState.commentTextValue}
              placeholder="Comment"
            />
          </div>

        </form>
      </MDBModalBody>
      <MDBModalFooter className="justify-content-center">
        {dialogState.id ? (
          <>
            <MDBBtn
              color="info"
              onClick={() => {
                wordGroupActions.addSuggestion(dialogState.regionId, getEditedState());
                toggleModal();
              }}
            >
              Update
            </MDBBtn>
          </>
        ) : (
          <MDBBtn
            color="info"
            onClick={() => {
              // TODO
              wordGroupActions.addSuggestion(dialogState.regionId, getEditedState());
              // TODO  appBus.emit("deselect");
              toggleModal();
            }}
          >
            Add
          </MDBBtn>
        )}
      </MDBModalFooter>
    </MDBModal>
  );
});
