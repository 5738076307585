import * as firebase from "firebase/app";
import "firebase/firestore";
import {computed, observable} from "mobx";
import {fastSortOnAtomIndex} from "./sort-on-atom-index";
import {getContentPosition} from "../lib/positional-ids"
import {SortedIntervals} from "./sorted-intervals";
import {
  appRoot,
  auth,
  scriptModel,
  wordGroupModel,
  wordSelection,
  anonymous,
  wordGroupActions,
  findForward, findReverse,
  auditLogActions
} from "../app/app-root";
import {openWordGroupDialog} from "../components/word-group-dialog";
import {randomString} from "../app/entity-utils";
import {openWordThreadGroupDialog} from "../components/word-group-thread-dialog";
import {warning} from "./warn";


export class WordGroupModel {
  @observable.ref wordGroupRegionsDict = null;

  // TODO need to figure if mapping can change and if observable


  get mapping() {
    return scriptModel.mapping;
  }

  @computed({ keepAlive: true})
  get focusedWordGroupRegion() {
    // TODO reorganize, unhack
    return wordGroupActions.getTargetWordGroupRegion();
  }

  @computed({ keepAlive: true })
  get wordGroupRegions() {
    const regions = Object.values(this.wordGroupRegionsDict).map((r) => new WordGroupRegion(r, this.mapping));
    const indexFunc = (r) => {
      return r.wordIndexRange.start;
    };
    fastSortOnAtomIndex(regions, indexFunc);
    return regions;
  }

  @computed({ keepAlive: true })
  get regionIdToRegion() {
    const result = {};
    for (const region of this.wordGroupRegions) {
      result[region.id] = region;
    }
    return result;
  }

  @computed({ keepAlive: true })
  get regionWordIntervals() {
    const regions = this.wordGroupRegions;
    const startPoints = regions.map((r) => r.wordIndexRange.start);
    const endPoints = regions.map((r) => r.wordIndexRange.end);
    return new SortedIntervals({startPoints, endPoints});
  }

  @computed({ keepAlive: true })
  get wordGroupMembership() {
    // TODO for each word index test if word group region interval containing, push word group region in array
    const len = this.mapping.mapping.contentLength;
    const result = new Array(len).fill(null);
    for (let i = 0; i < len; i++) {
      const region = this.regionContainingWordIndex(i);
      // TODO actually push in sub array
      result[i] = region;
    }
    return result;
  }

  @computed({ keepAlive: true })
  get wordGroupMembershipType() {
    // TODO for each word group membership get type put in array
    const membership = this.wordGroupMembership;
    const membershipType = membership.map((m) => {
      return m && {
        group_type:m.group_type,
        activated:m.activated,
        resolved:m.resolved,
        filled:m.filled,
      }
    });
    return membershipType;
  }

  regionContainingWordIndex(index) {
    // TODO actually should be plural regions
    const regionIndex = this.regionWordIntervals.containing(index);
    if (regionIndex >= 0) {
      return this.wordGroupRegions[regionIndex];
    }
    return null;
  }

  findForward(wordIndex, f) {
    const startIndex = this.regionWordIntervals.firstStartsAfter(wordIndex);
    if (startIndex === -1) {
      return startIndex;
    }
    return findForward(this.wordGroupRegions, startIndex, f);
  }

  findReverse(wordIndex, f) {
    let startIndex = this.regionWordIntervals.lastEndsBeforeOrAt(wordIndex);
    if (startIndex === -1) {
      return startIndex;
    }
    if (this.regionWordIntervals.doesContain(startIndex, wordIndex)) {
      startIndex--;
      if (startIndex < 0) {
        startIndex = 0;
      }
    }
    return findReverse(this.wordGroupRegions, startIndex, f);
  }
}


export class WordGroupRegion {
  // TODO FOR NOW just modeling as updates? so the last suggestion is selected one? no actions or discussion
  regionData;
  mapping;


  constructor(regionData, mapping) {
    this.regionData = regionData;
    this.mapping = mapping;
  }

  // TODO computed keepAlives?

  get group_type() {
    // TODO
    return this.regionData.group_type
  }

  get id() {
    return this.regionData.id;
  }

  @computed({ keepAlive: true })
  get suggestions() {
    const suggestionsData = this.regionData.suggestions;
    const suggestions = (suggestionsData) ? Object.values(this.regionData.suggestions) : [];
    suggestions.sort((a, b) => b.time - a.time);
    return suggestions;
  }

  @computed({ keepAlive: true })
  get actions() {
    const actionsData = this.regionData.actions;
    const actions = (actionsData) ? Object.values(this.regionData.actions) : [];
    actions.sort((a, b) => b.time - a.time);
    return actions;
  }

  @computed({ keepAlive: true })
  get discussion() {
    const commentsData = this.regionData.comments;
    const comments = (commentsData) ? Object.values(this.regionData.comments) : [];
    comments.sort((a, b) => b.time - a.time);
    return comments;
  }

  @computed({ keepAlive: true })
  get thread() {
    const thread = [];
    thread.push(...this.suggestions, ...this.actions, ...this.discussion);
    thread.sort((a, b) => b.time - a.time);
    return thread;
  }

  @computed({ keepAlive: true })
  get filteredThread() {
    if (!this.actions.length) {
      return this.thread;
    }
    let haveResolved = false;
    const result = [];
    for (const item of this.thread) {
      if (item.type !== 'ACTION' || item.action !== 'RESOLVE') {
        result.push(item);
      } else {
        if (!haveResolved) {
          result.push(item);
          haveResolved = true;
        }
      }
    }
    return result;
  }

  @computed({ keepAlive: true })
  get selected() {
    // TODO for now just last suggestion
    const suggestions = this.suggestions;
    for (const item of this.thread) {
      if (item.type === 'ACTION' && item.action === 'SELECT') {
        return suggestions.find(s => s.id === item.target);
      }
      if (item.type === 'ACTION' && item.action === 'RESET_SELECTION') {
        break;
      }
    }
    return suggestions[0];
  }

  @computed({ keepAlive: true })
  get activated() {
    // const resolved = this.resolved;
    // TODO fix this or not
    // if (!resolved || (resolved === 'ACTIVATED')) {
      return this.selected;
    // }
    // return null;
  }

  @computed({ keepAlive: true })
  get resolved() {
    const last = this.thread[0];
    if (last.type === 'COMMENT' && last.text.includes('(resolved)')) {
      return true;
    }
    return false;
    /***
    const last = this.thread[0];
    if (last.type === 'ACTION' && last.action === 'RESOLVE') {
      return last.state; // truthy for any resolve action
    }
    return null;
     **/
  }

  @computed({ keepAlive: true })
  get hasComment() {
    return this.discussion.length;
  }

  @computed({ keepAlive: true })
  get filled() {
    if (this.group_type === 'sic' || this.group_type === 'tricky') {
      return true;
    }
    return this.selected.note;
  }

  @computed({ keepAlive: true })
  get wordIndexRange() {
    // TODO min and max of indexes of suggestions
    const suggestions = this.suggestions;
    const first = suggestions[0];
    // TODO could use reduce
    let startPosition = this.mapping.getIndex(first.startPosition);
    let endPosition = this.mapping.getIndex(first.endPosition);
    // for (const suggestion of suggestions) {
    //   const start = this.mapping.getIndex(suggestion.startPosition);
    //   const end = this.mapping.getIndex(suggestion.endPosition);
    //   if (start < startPosition) {
    //     startPosition = start;
    //   }
    //   if (end > endPosition) {
    //     endPosition = end;
    //   }
    // }
    return {start:startPosition, end:endPosition};
  }
}


export class WordGroupActions {

  constructor() {
  }

  get dbPaths() {
    return appRoot.dbPaths;
  }

  addWordsFor(wordGroupData) {
    const range = {
      start: wordGroupData.startPosition,
      end: wordGroupData.endPosition
    };
    return { text: wordSelection.textForIdRange(range), ...wordGroupData };
  }

  getTargetWordGroupRegion() {
    const selection = wordSelection.wordRangeSelection;
    if (selection) {
      // TODO use selection INDEX range
      const singleWordSelection = selection.end === selection.start;
      if (singleWordSelection) {
        // TODO really should use wordRangeSelection or getCurrentWordIndex?
        const wordGroupRegion = wordGroupModel.regionContainingWordIndex(wordSelection.getCurrentWordIndex());
        if (wordGroupRegion) {
          return wordGroupRegion;
        }
      }
    }
    return null;
  }

  getOverlappingWordGroupRegionForRange(start, end) {
    // TODO check for overlap not just end point containment
    const startIndex = scriptModel.mapping.getIndex(start);
    const endIndex = scriptModel.mapping.getIndex(end) - 1;
    // TODO really should use wordRangeSelection or getCurrentWordIndex?
    const wordGroupRegion = wordGroupModel.regionContainingWordIndex(startIndex);
    if (wordGroupRegion) {
      return wordGroupRegion;
    }
    return wordGroupModel.regionContainingWordIndex(endIndex);
  }

  getOverlappingWordGroupRegion() {
    // TODO factor logic with getTargetWordGroupRegion
    const selection = wordSelection.wordRangeSelectionExclusiveEnd;
    if (selection) {
      return this.getOverlappingWordGroupRegionForRange(selection.start, selection.end);
    }
    return null;
  }

  removeFocusedWordGroup() {
    const targetWordGroupRegion = this.getTargetWordGroupRegion();
    if (!(targetWordGroupRegion && targetWordGroupRegion.activated)) {
      return false;
    }
    this.resolve(targetWordGroupRegion.id, 'DEACTIVATED');
    return true;
  }

  wordGroupHighlightAction(group_type) {
    if(anonymous() || !scriptModel.editingEnabled) {
      warning();
      return;
    }
    const overlappingWordGroupRegion = this.getOverlappingWordGroupRegion(group_type);
    if (overlappingWordGroupRegion) {
      warning();
      return;
    }
    const selection = wordSelection.wordRangeSelectionExclusiveEnd;
    // TODO TODO fix end selection
    if (!selection) {
      warning();
      return;
    }
    const regionId = `WORD_GROUP_REGION:${randomString(12)}`;
    const suggestion = this.addWordsFor({
      // comment: 'HIGHLIGHT',
      startPosition: selection.start,
      endPosition: selection.end,
      group_type
    });
    this.addSuggestion(regionId, suggestion);
  }

  wordGroupDialogAction(group_type) {
    const targetWordGroupRegion = this.getTargetWordGroupRegion(group_type);
    if (targetWordGroupRegion) {
      openWordThreadGroupDialog(targetWordGroupRegion.id)
      // let targetSuggestion = targetWordGroupRegion.selected;
      // targetSuggestion = this.addWordsFor(targetSuggestion);
      // openWordGroupDialog(targetWordGroupRegion.id, targetSuggestion);
    } else {
      if (anonymous() || !scriptModel.editingEnabled) {
        warning();
        return;
      }
      if (!group_type) {
        return;
      }
      const selection = wordSelection.wordRangeSelection;
      // TODO TODO fix end selection
      if (!selection) {
        return;
      }
      const regionId = `WORD_GROUP_REGION:${randomString(12)}`;
      const suggestion = this.addWordsFor({
        startPosition: selection.start,
        endPosition: selection.end + 1,
        group_type
      });
      openWordGroupDialog(regionId, suggestion);
    }
  }

  // TODO factor common code out of these firestore functions
  // TODO especially can factor code for these actions just passing partial action data as second param
  async select(id, suggestion) {
    const groupSuggestionsDocRef = this.dbPaths.groupSuggestionsDocRef;
    const actionId = `ACTION:${randomString(12)}`;
    const type = 'ACTION';
    const actionType = 'SELECT';
    const time = Math.round(Date.now() /1000);
    const author = auth.user.email;
    const actionData = {id:actionId, type, action:actionType, target:suggestion.id, author, time};
    const actions = {};
    actions[actionId] = actionData;
    const region = {id, actions};
    const regions = {[id]:region};
    const result = await groupSuggestionsDocRef.set({regions: regions} , {
      merge: true
    });
  }

  async resetSelection(id) {
    const groupSuggestionsDocRef = this.dbPaths.groupSuggestionsDocRef;
    const actionId = `ACTION:${randomString(12)}`;
    const type = 'ACTION';
    const actionType = 'RESET_SELECTION';
    const time = Math.round(Date.now() /1000);
    const author = auth.user.email;
    const actionData = {id:actionId, type, action:actionType, author, time};
    const actions = {};
    actions[actionId] = actionData;
    const region = {id, actions};
    const regions = {[id]:region};
    const result = await groupSuggestionsDocRef.set({regions: regions} , {
      merge: true
    });
  }

  async delete(id) {
    const groupSuggestionsDocRef = this.dbPaths.groupSuggestionsDocRef;
    const result = await groupSuggestionsDocRef.update({ ["regions." + id]: firebase.firestore.FieldValue.delete() });
    auditLogActions.addLogMessage('delete', {id});
  }


  async resolve(id, state) {
    if (state === 'DEACTIVATED') {
      // TODO hack this in for now
      this.delete(id);
      return;
    }
    const groupSuggestionsDocRef = this.dbPaths.groupSuggestionsDocRef;
    const actionId = `ACTION:${randomString(12)}`;
    const type = 'ACTION';
    const actionType = 'RESOLVE';
    const time = Math.round(Date.now() /1000);
    const author = auth.user.email;
    const actionData = {id:actionId, type, action:actionType, state, author, time};
    const actions = {};
    actions[actionId] = actionData;
    const region = {id, actions};
    const regions = {[id]:region};
    const result = await groupSuggestionsDocRef.set({regions: regions}, {
      merge: true
    });
  }

  async addComment(id, text) {
    const groupSuggestionsDocRef = this.dbPaths.groupSuggestionsDocRef;
    const commentId = `COMMENT:${randomString(12)}`;
    const type = 'COMMENT';
    const time = Math.round(Date.now() /1000);
    const author = auth.user.email;
    const commentData = {id:commentId, type, text, author, time};
    const comments = {};
    comments[commentId] = commentData;
    const region = {id, comments};
    const regions = {[id]:region};
    const result = await groupSuggestionsDocRef.set({regions: regions} , {
      merge: true
    });
  }

  // TODO resolve inconsistency in interface, other actions have {id} as first param
  async addSuggestion(regionId, data) {
    let comment = null;
    if (data.comment) {
      comment = data.comment.trim().length > 0 ? data.comment : null;
      delete data.comment;
    }
    if (data.startPosition === data.endPosition) {
      window.alert('word group create abort startPosition === endPosition bug');
      return;
    }
    const overlappingRegion = this.getOverlappingWordGroupRegionForRange(data.startPosition, data.endPosition);
    if (overlappingRegion && overlappingRegion.id !== regionId) {
      window.alert('detected overlapping word groups aborting');
      return;
    }
    const commentId = comment ? `COMMENT:${randomString(12)}` : null;
    const groupSuggestionsDocRef = this.dbPaths.groupSuggestionsDocRef;
    const id = `WORD_GROUP:${randomString(12)}`;
    const suggestionData = { ...data };
    // TODO check group_type is properly set
    const time = Math.round(Date.now() /1000);
    const author = auth.user.email;
    // TODO use literal
    suggestionData["type"] = "WORD_GROUP";
    suggestionData["id"] = id;
    suggestionData["editable"] = true;
    suggestionData["time"] = time;
    suggestionData["author"] = author;
    let oldEntity = null;
    const regionObj = wordGroupModel.regionIdToRegion[regionId];
    if (regionObj) {
      oldEntity = regionObj.selected;
    }
    auditLogActions.addLogMessage('add or update', suggestionData, oldEntity);
    const suggestions = {};
    suggestions[id] = suggestionData;
    const region = {};
    region.id = regionId;
    region.group_type = suggestionData.group_type;
    region.suggestions = suggestions;
    if (commentId) {
      const commentData = {id:commentId, type: 'COMMENT', text: comment, author, time};
      const comments = {};
      comments[commentId] = commentData;
      region.comments = comments;
    }
    const regions = {};
    regions[regionId] = region;

    const result = await groupSuggestionsDocRef.set({regions: regions} , {
      merge: true
    });
  }
}
