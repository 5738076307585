import React from "react";
import {ScriptEditor} from "../components/script-editor";
import {Alerts} from "../components/alerts";
import {appRoot, wordGroupModel} from "../app/app-root";
import {Discussion} from "../components/discussion";
import {StatusBar} from "../components/status-bar";
import {WordGroupDialog} from "../components/word-group-dialog";
import {TestingModal} from "../components/testing-modal";
import {TranslationDialog} from "../components/translation-dialog";
import {WordGroupThreadDialog} from "../components/word-group-thread-dialog";
import {observer} from "mobx-react";
import {WordGroupThread} from "../components/word-group-thread";
import {WordGroupTasksSheetDialog} from "../components/word-group-tasks-sheet-dialog";

const SideBar = observer((props) => {
  let wordGroupRegion = wordGroupModel.focusedWordGroupRegion;
  // TODO move to function somewhere
  if (wordGroupRegion && !wordGroupRegion.activated) {
    wordGroupRegion = null;
  }
  return (
    (wordGroupRegion)
      ?
      <div className={'discussion-sidepanel'} >
        <p className={'discussions-header'}>Word Group</p>
        <div style={{paddingLeft:15, paddingRight:15, paddingTop: 20}}>
          <WordGroupThread regionId={wordGroupRegion.id}/>
        </div>
      </div>
      :
      <div className={'discussion-sidepanel'}>
        <p className={'discussions-header'}>Line Comments</p>
        <Discussion/>
      </div>
  );
});

export default ({ match }) => {
  // this value comes from the URL, provided by react-router
  const {
    params: { scriptKey = null }
  } = match;
  appRoot.load(scriptKey);
  //  TODO make ScriptEditor just access the global singleton instead of passing prop?
  return (
    <div style={{height: "100%"}}>
      <div id={'main-container'}>
        <div className={'left-sidepanel'}/>
        <div className={'editor-main'}>
          <StatusBar/>
          <ScriptEditor/>
          <div className={'alerts'}>
            <Alerts/>
          </div>
        </div>
        <SideBar/>
        {/*<div className={'discussion-sidepanel'}>*/}
        {/*  <p className={'discussions-header'}>Comments</p>*/}
        {/*  <Discussion/>*/}
        {/*</div>*/}
        <div id={"tooltip"}>
          My tooltip
        </div>
        <WordGroupDialog/>
        <WordGroupThreadDialog/>
        <WordGroupTasksSheetDialog/>
        <TranslationDialog/>
        <TestingModal/>
      </div>
    </div>
  );
};
